import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { login, me } from 'store/auth/reducer';
import { Link, useNavigate } from 'react-router-dom';
import { getLoginLoading, getUserRole } from 'store/auth/selectors';
import { Role } from 'commun/constants';
import logo from '../../assets/img/logo-black.png'
import logoStar from '../../assets/img/logo-star.png'
Yup.setLocale({
    mixed: {
        required: 'Ce champ est requis',
    },
    string: {
        email: 'Veuillez entrer une adresse e-mail valide',
    },
});

type FormValues = {
    email: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
});

const initalValues = {
    email: '',
}

const ResetPwdView: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isLoading = useSelector(getLoginLoading);
    const role = useSelector(getUserRole)

    // useEffect(() => {
    //     if (role === Role.ADMIN)
    //         navigate('/admin/clients')
    //     else if (role === Role.CLIENT)
    //         navigate('/gestion')
    // }, [role])

    const handleSubmit = (
        values: FormValues,
    ) => {
        // dispatch(login(values) as any)
        //     .unwrap()
        //     .then(() => {
        //         dispatch(me() as any)
        //     })
    };

    return (
        <Container style={{
            display: 'flex',
            height: '70vh',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
        }} maxWidth='sm' >
            <Box >
                <Link to={'/'} style={{cursor: 'pointer'}}> <Container style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: 70
                }}>
                    <img src={logoStar} width={77 / 1} height={65 / 1} alt='logo-star' />
                    <img src={logo} width={250 / 1} height={82 / 1} alt='logo-header' />
                </Container>
                </Link>
                <Typography variant="h5">Récupérer votre mot de passe</Typography>
                <Formik
                    initialValues={initalValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div>
                                <Field
                                    name="email"
                                    type="email"
                                    label="Adresse e-mail"
                                    as={TextField}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    helperText={<ErrorMessage name="email" />}
                                />
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                            >
                                {isLoading ? '....' : 'Valider'}
                            </Button>
                        </Form>
                    )}
                </Formik>
                <Typography variant="body1" marginTop={5}>
                    <Link to="/login" style={{ marginLeft: '5px', }}>
                        Se connecter
                    </Link>
                </Typography>
            </Box>
        </Container >
    );
};

export default ResetPwdView;
