import * as React from 'react';
import SupportScene from './SupportScene';


interface SupportViewProps {
    
}
 
const SupportView: React.FC<SupportViewProps> = () => {
    return ( <SupportScene/> );
}
 
export default SupportView;