import { Popover } from '@mui/material';
import React, { FC, MouseEvent } from 'react';
import { LinksCard } from './LinksCard';

type Props = {
    anchorEl: any;
    handleClose: (event: MouseEvent<HTMLButtonElement>) => void
    cardLinks: []
}
export const LinksCardPopover: FC<Props> = ({
    anchorEl,
    handleClose,
    cardLinks
}) => {


    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    return <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        <LinksCard links={cardLinks} />
    </Popover>
}