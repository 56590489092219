import { AddCircleOutline } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import HomeLayout from "components/layouts/HomeLayout";
import React, { FC } from "react";

export const MediaBankScene: FC = () => {
  return (
    <HomeLayout>
      <Container style={{ minHeight: "78vh" }}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <h2>Banque d’images</h2>
          </Grid>
          <Grid item alignSelf={"center"}>
            <AddCircleOutline
              fontSize={"large"}
              onClick={() => {
                //
              }}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 15 }}></div>
      </Container>
    </HomeLayout>
  );
};
