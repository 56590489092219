import { Box, Button, Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface SimpleCardProps {
    title: string;
    description: string;
    btnText?: string;
    onClick?: () => void;
    revert?: boolean
}

const SimpleCard: FC<SimpleCardProps> = ({
    title,
    description,
    btnText,
    onClick = () => { },
    revert = false,
}) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    return (<>
        <Card variant="outlined" sx={{ zIndex: 10000, fontFamily: 'Poppins', backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
            <CardContent >
                <Typography variant="h5" component="div" sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: isMobile ? 22 : 36,
                    textAlign: isMobile || revert ? 'center' : 'start',
                    color: revert ? 'white' : 'white'
                }}>
                    {title}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    textShadow: isMobile && revert ? '1px 1px 2px black' : undefined,
                    fontSize: isMobile ? 13 : 18,
                    textAlign: isMobile ? 'center' : 'start',
                    color: revert ? 'white' : 'white'

                }}>
                    {description}
                </Typography>
                {btnText && <Box sx={{ display: 'flex', justifyContent: `${revert ? 'center' : 'flex-end'}`, marginTop: 2, color: 'white' }}>
                    <Button variant="outlined" onClick={onClick} color={`${revert ? 'inherit' : 'primary'}`} sx={{ fontFamily: 'Poppins',color: 'white', borderColor: 'white' }} endIcon={<ArrowForwardIcon />}>
                        {btnText}
                    </Button>
                </Box>}
            </CardContent>
        </Card></>);
}

export default SimpleCard;