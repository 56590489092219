import axios from "axios";
import { BASE_URL } from "store/constants";
import { AddClientInput } from "./types";

export const fetchClientsService = (token: string) =>
  axios.get(
    `${BASE_URL}/api/users?filters[role][name][$eq]=Client&populate=product_lines`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchProductsLine = (token: string) =>
  axios.get(`${BASE_URL}/api/product-lines`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const editClientProduct = (
  token: string,
  id: number,
  productId: number
) =>
  axios.put(
    `${BASE_URL}/api/users/${id}`,
    {
      product_lines: productId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addClient = (token: string, input: AddClientInput) =>
  axios.post(`${BASE_URL}/api/users`, input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteClient = (token: string, id: number) =>
  axios.delete(`${BASE_URL}/api/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
