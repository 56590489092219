import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { UserState } from "store/auth/types";
import {
  Button,
  FormControl,
  Modal,
  NativeSelect,
  Typography,
} from "@mui/material";
import { ProductLineType } from "store/admin/types";
import { useDispatch } from "react-redux";
import {
  deleteClientAction,
  editClientProductAction,
} from "store/admin/thunkActions";
import { DeleteForeverOutlined } from "@mui/icons-material";

interface Column {
  id:
    | "entreprise"
    | "nom"
    | "prenom"
    | "adresse_email"
    | "telephone"
    | "produit";
  label: string;
  minWidth?: number;
  align?: "right" | "center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "entreprise", label: "Entreprise", minWidth: 170 },
  { id: "nom", label: "Nom", minWidth: 100 },
  {
    id: "prenom",
    label: "Prénom",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("fr-FR"),
  },
  {
    id: "adresse_email",
    label: "Adresse email",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "telephone",
    label: "Téléphone",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "produit",
    label: "Produit",
    minWidth: 170,
    align: "center",
    format: (value: number) => value.toFixed(2),
  },
];

export type DataType = UserState & {
  product_lines: {
    reference: string;
  };
};

function adapteData(data: DataType[]) {
  return data.map((item) => ({
    id: item.id,
    entreprise: item.company,
    nom: item.last_name,
    prenom: item.name,
    adresse_email: item.email,
    telephone: item.phone,
    produit: item.product_lines,
  }));
}

type Props = {
  data: UserState[];
  products: ProductLineType[];
};

export const StickyHeadTable: React.FC<Props> = ({ data, products }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState();

  const renderConfimationDeleteModal = React.useCallback(() => {
    return (
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ backgroundColor: "white", padding: 10, width: 400 }}>
          <Typography variant="h6" paddingBottom={2}>
            Confirmation de suppression ?
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              // dipatch deleteAction
              userToDelete && dispatch(deleteClientAction(userToDelete) as any);
              setOpenDeleteModal(false);
              setUserToDelete(undefined);
            }}
          >
            Continuer
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={() => setOpenDeleteModal(false)}
          >
            Annuler
          </Button>
        </div>
      </Modal>
    );
  }, [openDeleteModal, setOpenDeleteModal, userToDelete, setUserToDelete]);

  const rows = adapteData(data as DataType[]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if ("produit" === column.id)
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <FormControl
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <NativeSelect
                                value={value[0]?.id ?? "noval"}
                                onChange={(e) => {
                                  dispatch(
                                    editClientProductAction({
                                      id: row?.["id"],
                                      productId: +e.target.value,
                                    }) as any
                                  );
                                }}
                                inputProps={{
                                  id: "uncontrolled-native",
                                }}
                              >
                                <option value={"noval"}></option>
                                {products.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.reference}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                              <DeleteForeverOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOpenDeleteModal(true);
                                  setUserToDelete(row?.id);
                                }}
                              />
                            </FormControl>
                          </TableCell>
                        );
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Lignes par page"}
      />
      {renderConfimationDeleteModal()}
    </Paper>
  );
};
