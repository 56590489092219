import { createSlice } from "@reduxjs/toolkit";
import { fetchProductLinesAction } from "./thunkActions";
import { ProductLinesState } from "./types";

const initialState: ProductLinesState = {
  isLoading: false,
  error: null,
  product_lines: [],
};
const productLinesSlice = createSlice({
  name: "productLines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductLinesAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProductLinesAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.product_lines = payload.product_lines;
      })
      .addCase(fetchProductLinesAction.rejected, (state) => {
        state.isLoading = false;
        state.error = "ERREUR"; // todo
        state.product_lines = [];
      });
  },
});

export const productLinesReducer = productLinesSlice.reducer;
