import axios from "axios";
import { API_URL } from "config";
import { ProductLinesInputType } from "./types";

export const fetchProductLines = (token: string) =>
  axios.get(
    `${API_URL}/api/users/me?populate[product_lines][populate][0]=subscription&populate[product_lines][populate][1]=product&populate[product_lines][populate][2]=playlist.medias&fields[0]=id`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateProductLines = (
  token: string,
  id: number,
  payload: ProductLinesInputType
) =>
  axios.put(
    `${API_URL}/api/product-lines/${id}`,
    { data: payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
