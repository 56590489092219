import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import store, { persistor } from './store'
import { Provider } from 'react-redux'
import Routes from 'navigation/Routes';
import { PersistGate } from 'redux-persist/integration/react';


/**
 * TODO: 
 * 0 - Deliver & deploy strapi (first);
 * 1 - download brochure for Helios | Solaris;
 * 2 - finish Applications page;
 * 2.1 - solve slider problem
 * 3 - deliver app;
 */
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <Router>
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
