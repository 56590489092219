import { Button, Container, Grid } from '@mui/material';
import HomeLayout from 'components/layouts/HomeLayout';
import * as React from 'react';


interface SupportSceneProps {

}

const SupportScene: React.FC<SupportSceneProps> = () => {
    const [isActive, setIsActive] = React.useState(false)
    return (
        <HomeLayout>
            <Container component="main" style={{ margin: 20, paddingRight: 30 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <h2>Support</h2>
                        Votre agent :
                        <b>Adrien Houssin</b>
                        <br />
                        E-mail :
                        <b>adrien.houssin@stargetholographics.com</b>
                        <h2>Abonnement premium</h2>
                        <div>
                            {isActive ? <p>
                                Souscrivez à cette offre pour générer des <b> vidéos 3D personnalisées par intelligence artificielle</b>, et vous bénéficiez
                                d’une <b>assistance en ligne</b>, incluant la prise en main à distance de votre support pour y faire les modifications que
                                vous souhaitez, un <b>opérateur disponible</b> au téléphone en priorité et un <b>accès illimité à notre banque de vidéos 3D</b>
                                pour vos supports.
                            </p> :
                                <p>
                                    Vous pouvez générer des <b> vidéos 3D personnalisées par intelligence artificielle </b> et vous bénéficiez
                                    <b> d’une assistance en ligne</b>,
                                    incluant la prise en main à distance de votre support pour y faire les modifications que vous souhaitez, un<b> opérateur
                                        disponible </b>au téléphone en priorité et un <b>accès illimité à notre banque de vidéos 3D</b> pour vos supports.
                                </p>}
                        </div>
                        {!isActive && <i>Tarif : 99€ / mois HT</i>}
                        <br />
                        <Button
                            sx={{
                                background: '#0E1B4D',
                                border: '1px solid rgba(14, 27, 77, 0.7)',
                                borderRadius: 90,
                                marginTop: '20px',
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {
                                // submitting ?
                                // <CircularProgress size={20} color="inherit" /> :
                                !isActive ? 'Souscrire' : 'Annuler'
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </HomeLayout>
    );
}

export default SupportScene;