import { Box, Button } from '@mui/material';
import React, { FC, useState } from 'react';
import './style.css';

interface SwitchProps {
    values: string[];
    activeValue: React.Dispatch<React.SetStateAction<string>>
}

const Switch: FC<SwitchProps> = ({
    values,
    activeValue
}) => {
    const [subType, setSubType] = useState(values[0]);
    return (<>
        <Box sx={{ display: "flex", marginTop: 5, marginLeft: 5 }}>
            <Box className="mask-box">
                <Box
                    className="mask"
                    style={{
                        transform: `translateX(${subType === values[0] ? 0 : "130px"})`
                    }}
                />
                <Button
                    disableRipple
                    variant="text"
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'normal',
                        fontSize: 26,
                        color: subType === values[0] ? "#0E1B4D" : "#ffffff"
                    }}
                    onClick={() => {
                        setSubType(values[0])
                        activeValue(values[0])
                    }}
                >
                    {values[0]}
                </Button>
                <Button
                    disableRipple
                    variant="text"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: 26,
                        color: subType === values[1] ? "#0E1B4D" : "#ffffff"
                    }}
                    onClick={() => {
                        setSubType(values[1])
                        activeValue(values[1])
                    }}
                >
                    {values[1]}
                </Button>
            </Box>
        </Box>
    </>);
}

export default Switch;