import React, { FC } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import { legalMentions } from '../../../datalayer/legal/legalMentions';

interface LegalMentionsProps {

}

const LegalMentions: FC<LegalMentionsProps> = () => {
    return (<HomeLayout>
        <div dangerouslySetInnerHTML={{ __html: legalMentions }} />
    </HomeLayout>);
}

export default LegalMentions;