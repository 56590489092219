import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Role } from 'commun/constants';
import { BASE_URL } from 'store/constants';
import { AuthState, LoginApiType, LoginInput, MeApiType, UserState } from './types';

const initialState: AuthState = {
    isLoading: false,
    isLoggedIn: false,
    error: null,
    token: null,
    user: null,
    activeRole: Role.PUBLIC,
};

export const login = createAsyncThunk(
    'auth/login',
    async (input: LoginInput) => {
        const { email, password } = input
        try {
            // Your API call logic here using Axios
            const response = await axios.post(`${BASE_URL}/api/auth/local`, { identifier: email, password });
            return response.data as LoginApiType;
        } catch (error) {
            throw Error('Failed to log in'); // Customize error handling as needed
        }
    }
);
export const me = createAsyncThunk(
    'auth/me',
    async (_, thunkApi) => {
        try {
            const state = thunkApi.getState() as any
            // Your API call logic here using Axios
            const response = await axios.get(`${BASE_URL}/api/users/me?populate=role&fields[0]=id`, {
               headers: {
                'Authorization': `Bearer ${state.auth.token}`
               } 
            });
            return response.data as MeApiType;
        } catch (error) {
            throw Error('me to log in'); // Customize error handling as needed
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.error = null;
            state.token = null;
            state.user = null
        })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isLoggedIn = true;
                state.error = null;
                state.token = payload.jwt;
                state.user = payload.user;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || null;
            }).addCase(me.fulfilled, (state, { payload }) => {
                if (state.user)
                    state.activeRole = payload.role.name.toUpperCase();
            })
    },
})

export const { logout } = authSlice.actions
export const authReducer = authSlice.reducer