import { Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Container, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import logo from '../../../assets/img/logo.svg'
import { useNavigate } from 'react-router-dom';
interface FooterProps {

}

const SimpleFooter: FC<FooterProps> = () => {
    const navigate = useNavigate()
    return (<>
        <Box bgcolor="#0E1B4D" sx={{ zIndex: 10 }} color={'white'} >
            <Container>
                <Grid container >
                    {/* First Row */}
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                {/* Logo */}
                                <Typography onClick={() => { navigate('/') }} variant="h6" sx={{ fontFamily: 'Poppins', cursor: 'pointer' }}>
                                    <img src={logo} width={250 / 1.35} height={82 / 1.35} alt='logo-footer' />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="space-between" alignItems="center" flexDirection={'row'}>

                                    {/* Links */}
                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins', padding: 2, cursor: 'pointer' }} onClick={() => { navigate('/produits/contact') }}>Nous contacter</Typography>
                                    {/* Privacy Policy */}
                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins', padding: 2, cursor: 'pointer' }} onClick={() => { navigate('/politque-de-confidentialite') }}>Politique de Confidentialité</Typography>
                                    {/* CGU */}
                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins', padding: 2, cursor: 'pointer' }} onClick={() => { navigate('/cgv') }}>CGV</Typography>
                                    {/* Legal Mentions */}
                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins', padding: 2, cursor: 'pointer' }} onClick={() => { navigate('/mentions-legales') }}>Mentions légales</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {/* Social Media Icons */}
                                <LinkedIn style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.linkedin.com/company/starget-holographics/', '_blank') }} />
                                <Instagram style={{ cursor: 'pointer' }} onClick={() => { window.open('https://instagram.com/stargetholographics?igshid=MzRlODBiNWFlZA==', '_blank') }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* </Grid> */}
            </Container>
        </Box>
    </>);
}

export default SimpleFooter;
