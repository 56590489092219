import * as React from "react";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import { styled } from "@mui/system";
import Card from "@mui/material/Card";

import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { PlayListType } from "store/client/gestion/types";

const VideoCard = styled(Card)({
  border: "1px solid #ccc",
  borderRadius: "8px",
  margin: "10px",
  position: "relative",
});

const VideoHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
});

const Title = styled("h3")({
  margin: "0",
});

const VideoThumbnail = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "6px", // Rounded corners for thumbnails
});

const ThumbnailContainer = styled("div")({
  position: "relative",
  maxWidth: "300px", // Adjust width as needed
  margin: "10px",
});

const ThreeDotsIcon = styled(IconButton)({
  position: "absolute",
  top: "8px",
  right: "8px",
});

const TrashIcon = styled(IconButton)({
  //   position: "absolute",
  bottom: "8px",
  right: "8px",
});

const AddIconBtn = styled(IconButton)({
  position: "absolute",
  top: "8px",
  right: "8px",
});

type Props = {
  playlist: PlayListType;
};

export const MediaForm: React.FC<Props> = ({ playlist }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTrashClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    // Perform delete action here
    setOpenDialog(false);
  };

  const handleAddVideo = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFileUpload = (event: any) => {
    const files = event.target.files;
    // Handle the uploaded files here
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // Handle the dropped files here
  };

  return (
    <VideoCard style={{ height: 559 }}>
      <VideoHeader style={{ marginTop: 20 }}>
        <Title>Playlist</Title>
        <AddIconBtn aria-label="add-video" onClick={handleAddVideo}>
          <AddCircleOutlineRounded fontSize="large" />
        </AddIconBtn>
      </VideoHeader>

      <Grid container overflow={"auto"} style={{ height: 430 }}>
        {/* {/* Add your content for the right side  */}
        {playlist?.medias?.map((thumbnail, index) => (
          <Grid key={index} item xs={10} sm={6} md={4}>
            <ThumbnailContainer>
              <VideoThumbnail
                src={
                  "https://codesandbox.io/api/v1/sandboxes/ux79q/screenshot.png"
                }
                alt={`Video Thumbnail ${index}`}
              />
              <ThreeDotsIcon
                style={{ color: "white" }}
                aria-label="options"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </ThreeDotsIcon>
            </ThumbnailContainer>
          </Grid>
        ))}
      </Grid>
      <div style={{ paddingTop: 20 }}>
        <CardActions disableSpacing style={{ flexDirection: "row-reverse" }}>
          <TrashIcon aria-label="delete" onClick={handleTrashClick}>
            <DeleteIcon />
          </TrashIcon>
        </CardActions>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Lire</MenuItem>
          <MenuItem onClick={handleClose}>Supprimer</MenuItem>
        </Menu>
      </div>
    </VideoCard>
  );
};
