import {
  AppBar,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import logo from "../../../assets/img/logo-black.png";
import logoStar from "../../../assets/img/logo-star.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import CloudDoneIcon from "@mui/icons-material/CloudDoneOutlined";
import ApartmentIcon from "@mui/icons-material/ApartmentOutlined";
import SupportIcon from "@mui/icons-material/SupportOutlined";
import { Role } from "commun/constants";
import { useSelector } from "react-redux";
import { getUserRole } from "store/auth/selectors";
import { LinksCardPopover } from "components/cards/links-card/LinksCardPopover";
import { DashboardOutlined } from "@mui/icons-material";
interface HeaderProps {
  menuItems: {
    text: string;
    path: string;
  }[];
}

const sharedLinks = [
  {
    icon: <LogoutIcon />,
    title: "Déconnexion",
    to: "/logout",
  },
];
const adminLinks = [
  {
    icon: <PeopleIcon />,
    title: "Comptes clients",
    to: "/clients",
  },
  {
    icon: <CloudDoneIcon />,
    title: "Banque d’images",
    to: "/banque-image",
  },
  ...sharedLinks,
];

const clientLinks = [
  {
    icon: <DashboardOutlined />,
    title: "Dashboard",
    to: "/gestion",
  },
  {
    icon: <ApartmentIcon />,
    title: "Mes informations",
    to: "/info-personnelles",
  },
  {
    icon: <SupportIcon />,
    title: "Support",
    to: "/support",
  },
  ...sharedLinks,
];
const links: any = {
  [Role.ADMIN]: adminLinks,
  [Role.CLIENT]: clientLinks,
  [Role.PUBLIC]: [],
};

const Header: FC<HeaderProps> = ({ menuItems }) => {
  const role = useSelector(getUserRole);
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)"); // Change the breakpoint as needed
  const toggleDrawer = () => {
    console.log("HANDLE TOGGLE");
    setOpenDrawer(true);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginRedirection = (e: any) => {
    if (role === Role.PUBLIC) navigate("/login");
    else {
      handleClick(e);
    }
  };

  const renderPublicMenu = useCallback(() => {
    if (role !== (Role.PUBLIC as any)) return;
    return (
      <Grid item>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={openDrawer}>
              <Grid item>
                <Typography
                  onClick={() => {
                    navigate("/");
                  }}
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, cursor: "pointer", padding: 2 }}
                >
                  <img
                    src={logoStar}
                    width={77 / 1.8}
                    height={65 / 1.8}
                    alt="logo-star"
                  />
                  <img
                    src={logo}
                    width={250 / 1.8}
                    height={82 / 1.8}
                    alt="logo-header"
                  />
                </Typography>
              </Grid>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={() => {
                  setOpenDrawer(false);
                }}
                style={{ position: "absolute", top: 10, right: 10 }}
              >
                <CloseIcon />
              </IconButton>
              <List sx={{ width: "100vw", marginTop: 15 }}>
                {menuItems?.map((item) => (
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 25,
                          fontWeight: "bolder",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 20,
                          fontWeight: "bold",
                        },
                      }}
                      onClick={() => {
                        navigate(item.path);
                      }}
                      primary={pathName === item.path ? item.text : undefined}
                      secondary={pathName === item.path ? undefined : item.text}
                    />
                  </ListItem>
                ))}
                <ListItem
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    handleLoginRedirection(e);
                  }}
                >
                  <AccountCircleOutlinedIcon />
                  <span style={{ marginLeft: 5 }}>Se connecter</span>
                </ListItem>
                <LinksCardPopover
                  anchorEl={anchorEl}
                  cardLinks={links[role]}
                  handleClose={handleClose}
                />
              </List>
            </Drawer>
          </>
        ) : (
          <>
            {menuItems?.map((item) => (
              <Button
                color="inherit"
                onClick={() => {
                  navigate(item.path);
                }}
                sx={{
                  fontFamily: "",
                  margin: "0 45px",
                  fontWeight: pathName === item.path ? "bold" : "normal",
                }}
              >
                {item.text}
              </Button>
            ))}
          </>
        )}
      </Grid>
    );
  }, [role, openDrawer]);

  const renderClientMenu = useCallback(() => {
    if (role === (Role.PUBLIC as any) || role === (Role.ADMIN as any)) return;
    return <Grid item>Dashboard de gestion</Grid>;
  }, [role]);

  const renderAdminMenu = useCallback(() => {
    if (role === (Role.PUBLIC as any) || role === (Role.CLIENT as any)) return;
    return <Grid item>Dashboard de gestion - Interne Starget</Grid>;
  }, [role]);

  return (
    <AppBar
      position={`${isMobile ? "relative" : "static"}`}
      color="transparent"
      style={{ backgroundColor: "white" }}
    >
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              onClick={() => {
                navigate("/");
              }}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, cursor: "pointer" }}
            >
              <img
                src={logoStar}
                width={77 / 1.8}
                height={65 / 1.8}
                alt="logo-star"
              />
              <img
                src={logo}
                width={250 / 1.8}
                height={82 / 1.8}
                alt="logo-header"
              />
            </Typography>
          </Grid>
          {renderPublicMenu()}
          {renderClientMenu()}
          {renderAdminMenu()}
          {!isMobile && (
            <Grid item>
              <Button
                onClick={(e) => {
                  // Handle navigation to mgt interface
                  handleLoginRedirection(e);
                }}
              >
                {" "}
                <AccountCircleOutlinedIcon />
              </Button>
              <LinksCardPopover
                anchorEl={anchorEl}
                cardLinks={links[role]}
                handleClose={handleClose}
              />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
