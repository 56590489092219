import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import HomeLayout from "components/layouts/HomeLayout";
import ControlForm from "./components/ControlForm";
import { MediaForm } from "./components/MediaForm";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductLinesAction } from "store/client/gestion/thunkActions";
import { RootState } from "store";
import { getClientProductLines } from "store/client/gestion/selectors";

const VideoPlaylistCard = () => {
  const dispatch = useDispatch();
  const productLines = useSelector(getClientProductLines);

  useEffect(() => {
    dispatch(fetchProductLinesAction() as any);
  }, []);

  return (
    <HomeLayout>
      <Container maxWidth="xl">
        {productLines.map((item, index) => {
          const hasSubscription =
            item.subscription && item.subscription.status === "Active";
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                {/* Add your content for the left side */}
                <ControlForm
                  id={item.id}
                  productName={`${item.product.name} ${item.reference}`}
                  brightness={item.brightness}
                  endTime={item.endTime}
                  startTime={item.startTime}
                  state={item.state}
                  hasSubscription={hasSubscription}
                />
              </Grid>
              {/* Right Content */}
              <Grid item xs={12} sm={12} md={8}>
                <MediaForm playlist={item.playlist} />
              </Grid>
              <Container>
                {index !== productLines.length - 1 && (
                  <div
                    style={{
                      backgroundColor: "#0E1B4D",
                      height: 1,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  ></div>
                )}
              </Container>
            </Grid>
          );
        })}
      </Container>
    </HomeLayout>
  );
};

export default VideoPlaylistCard;
