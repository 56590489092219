export const cgu = `
<style>
    h1 {
        text-align: center;
        font-size: 20px;
        font-family: Helvetica, Arial, sans-serif;
        margin: 0;

    }

    p {
        margin-bottom: 10px;
        font-size: 15 px;
        font-family: Helvetica, Arial, sans-serif;
    }

    .content {
        max-width: 800px;
        margin: 0 auto;
        padding: 50px;
        Height: 100%;
    }

    .footer {
        text-align: center;
        margin-top: 50px;
    }
</style>
<div class="content">
<p><strong>CONDITIONS GENERALES DE VENTES &ndash; STARGET HOLOGRAPHICS</strong></p>
<p><br></p>
<p><br></p>
<p><strong>Article 1. Les Parties&nbsp;</strong></p>
<p><br></p>
<p>Le terme le &laquo; Prestataire &raquo; d&eacute;signe la soci&eacute;t&eacute; STARGET HOLOGRAPHICS, SAS au capital de 5000 euros, immatricul&eacute;e au Registre du Commerce et des Soci&eacute;t&eacute;s de Paris sous le num&eacute;ro SIREN 922595053, et dont le si&egrave;ge social est situ&eacute; 60 Rue Fran&ccedil;ois 1ER, 75008 Paris, France.</p>
<p><br></p>
<p>Le Prestataire est une entreprise sp&eacute;cialis&eacute;e notamment dans le d&eacute;veloppement et la vente de mat&eacute;riel holographique, avec prestations associ&eacute;s et cr&eacute;ation de contenus audiovisuels.&nbsp;</p>
<p><br></p>
<p>Le terme &laquo; Client &raquo; d&eacute;signe toute personne morale ou physique, ayant requis les comp&eacute;tences du Prestataire.&nbsp;</p>
<p>Le terme &laquo; Tiers &raquo; d&eacute;signe toute personne physique ou morale non-partie &agrave; ces Conditions G&eacute;n&eacute;rales de Vente (&laquo; CGV &raquo;).&nbsp;</p>
<p><br></p>
<p><strong>Article 2. G&eacute;n&eacute;ralit&eacute;s&nbsp;</strong></p>
<p><br></p>
<p>Les pr&eacute;sentes CGV ont pour objet de d&eacute;finir les droits et obligations des Parties lors de la r&eacute;alisation de prestations de services r&eacute;alis&eacute;es par le Prestataire pour le Client dans le cadre de ses activit&eacute;s.</p>
<p>Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente s&apos;appliquent &agrave; tout contrat conclu entre le Prestataire et le Client, dans le cadre de la fourniture de prestations de services (les &laquo; Services &raquo;) telles que d&eacute;finies dans le devis valid&eacute; par le Client.&nbsp;</p>
<p><br></p>
<p>Les Services peuvent &ecirc;tre fournis soit &agrave; distance, soit dans les locaux du Client, au choix du Prestataire et du Client.</p>
<p>Les CGV sont syst&eacute;matiquement adress&eacute;es ou remises &agrave; chaque Client avant la passation de toute commande (la &laquo; Commande &raquo;).&nbsp;</p>
<p>En cons&eacute;quence, le fait de passer Commande implique l&apos;adh&eacute;sion enti&egrave;re et sans r&eacute;serve du Client &agrave; ces CGV, &agrave; l&apos;exclusion de tous autres documents en sa possession tels que prospectus, catalogues ou plaquettes publicitaires &eacute;mis par le Prestataire, lesquels n&apos;auront qu&apos;une valeur indicative et non contractuelle.&nbsp;</p>
<p>En cas de contradiction entre des dispositions du devis et les pr&eacute;sentes CGV, les dispositions concern&eacute;es du devis pr&eacute;vaudront sur les CGV.&nbsp;</p>
<p>Ces CGV r&eacute;gissent int&eacute;gralement les relations entre le Prestataire et le Client. Aucune condition g&eacute;n&eacute;rale d&apos;achat ne pourra pr&eacute;valoir ni &ecirc;tre oppos&eacute;e par le Client au Prestataire et aucune condition particuli&egrave;re communiqu&eacute;e par le Client au Prestataire ne peut pr&eacute;valoir sur les CGV, sauf acceptation formelle et &eacute;crite du Prestataire.&nbsp;</p>
<p>Toute r&eacute;serve concernant les CGV, avanc&eacute;e par le Client sera, donc, &agrave; d&eacute;faut d&apos;acceptation expresse par le Prestataire, inopposable &agrave; ce dernier, quel que soit le moment o&ugrave; elle aura pu &ecirc;tre port&eacute;e &agrave; sa connaissance.&nbsp;</p>
<p>Toutes dispositions d&eacute;rogeant aux pr&eacute;sentes CGV devront r&eacute;sulter d&rsquo;un accord expr&egrave;s des Parties, refl&eacute;t&eacute; dans les Commandes confirm&eacute;es par le Prestataire ou tout autre document faisant foi de l&rsquo;accord des deux Parties.&nbsp;</p>
<p>Le fait que le Prestataire ne se pr&eacute;vale pas &agrave; un moment donn&eacute; d&apos;une quelconque condition des pr&eacute;sentes CGV ne peut &ecirc;tre interpr&eacute;t&eacute; comme valant renonciation &agrave; se pr&eacute;valoir ult&eacute;rieurement de quelconque desdites conditions.&nbsp;</p>
<p>Le Prestataire se r&eacute;serve le droit de modifier ces CGV, les Services et les tarifs &agrave; tout moment et sans pr&eacute;avis.&nbsp;</p>
<p>Ces modifications n&apos;auront aucune incidence sur les Commandes en cours. Les CGV s&rsquo;appliquent aux seuls professionnels &agrave; l&rsquo;exclusion des consommateurs.&nbsp;</p>
<p>A ce titre, le Client reconna&icirc;t avoir la qualit&eacute; de professionnel, conform&eacute;ment aux dispositions du Code de la consommation applicables.</p>
<p><br></p>
<p><strong>Article 3. Conclusion du Contrat</strong></p>
<p><br></p>
<p>Sauf convention contraire dans le devis, le Contrat est r&eacute;put&eacute; form&eacute; et prend effet entre les Parties &agrave; la date de signature du devis par le client.&nbsp;</p>
<p>Aucun changement ni aucune modification du Contrat, notamment sur les caract&eacute;ristiques des Services, ne sera pris en consid&eacute;ration s&apos;il n&apos;a pas &eacute;t&eacute; accept&eacute; par &eacute;crit par le Prestataire.&nbsp;</p>
<p>Cette disposition ne peut &ecirc;tre remplac&eacute;e par un accord verbal.&nbsp;</p>
<p><br></p>
<p>A d&eacute;faut de dispositions sp&eacute;cifiques dans le devis, les d&eacute;lais d&rsquo;ex&eacute;cution des Services sont communiqu&eacute;s au Client &agrave; titre indicatif. Les d&eacute;lais de r&eacute;alisation des Services ne sont en aucun cas garantis par le Prestataire ni ne peuvent engager sa responsabilit&eacute;, ni entra&icirc;ner une obligation de payer une quelconque indemnit&eacute; ou p&eacute;nalit&eacute; de retard, ni justifier l&rsquo;annulation de la Commande en cause.</p>
<p><br></p>
<p><strong>Article 4. Obligations des Parties&nbsp;</strong></p>
<p><br></p>
<p>D&rsquo;une fa&ccedil;on g&eacute;n&eacute;rale, le Client et le Prestataire s&rsquo;engagent &agrave; collaborer activement afin d&rsquo;assurer la bonne ex&eacute;cution du Contrat. Chacune des Parties s&rsquo;engage &agrave; communiquer toutes les difficult&eacute;s dont elle aurait connaissance au fur et &agrave; mesure de l&apos;avancement du projet, pour permettre &agrave; l&rsquo;autre Partie de prendre les d&eacute;cisions n&eacute;cessaires.</p>
<p><br></p>
<p>Le Client s&apos;engage &agrave; fournir des informations justes et sinc&egrave;res et s&apos;engage aussi &agrave; pr&eacute;venir le Prestataire de tout changement concernant les informations, donn&eacute;es, documentations fournies.&nbsp;</p>
<p><br></p>
<p>Le Client sera seul responsable des &eacute;ventuels dysfonctionnements qui pourraient r&eacute;sulter d&apos;informations erron&eacute;es. Le Client doit maintenir une adresse e-mail et une adresse postale valides.</p>
<p><br></p>
<ul>
    <li>Obligations du Client&nbsp;</li>
</ul>
<p><br></p>
<p>Le Client d&eacute;clare express&eacute;ment avoir re&ccedil;u du Prestataire toutes les informations et tous les conseils n&eacute;cessaires &agrave; la r&eacute;alisation des Services et renonce &agrave; rechercher la responsabilit&eacute; du Prestataire de ce fait. Pour permettre au Prestataire de r&eacute;aliser sa mission, le Client s&rsquo;engage &agrave; :&nbsp;</p>
<p>- Collaborer &eacute;troitement avec le Prestataire et fournir toute information, documentation, prestation, et tous moyens utiles pour la r&eacute;alisation des Services et s&rsquo;engage &agrave; mettre &agrave; disposition du Prestataire tous les &eacute;l&eacute;ments permettant de satisfaire &agrave; son obligation, incluant le personnel d&eacute;di&eacute; &agrave; la bonne r&eacute;alisation des Services.&nbsp;</p>
<p>- &Eacute;tablir un cahier des charges d&eacute;taill&eacute; qui ne subira plus de modification, sauf accord des Parties, apr&egrave;s avoir &eacute;t&eacute; approuv&eacute; par le Prestataire. Au besoin, le Prestataire pourra intervenir dans l&rsquo;&eacute;laboration du cahier des charges, conjointement avec le Client. Dans le cas o&ugrave; des modifications impliqueraient un remaniement substantiel du cahier des charges initial, ces derni&egrave;res seront factur&eacute;es en sus du devis initial.&nbsp;</p>
<p>- Remettre au Prestataire le devis (dat&eacute;, sign&eacute; et tamponn&eacute;).&nbsp;</p>
<p>- Fournir tous les &eacute;l&eacute;ments documentaires, graphiques et textuels n&eacute;cessaires &agrave; la bonne r&eacute;alisation du Contrat (notamment dans les bons formats exploitables en fonction des supports vis&eacute;s), le Client s&apos;engage &agrave; fournir toutes les informations l&eacute;gales &agrave; ajouter dans les documents et endosse la responsabilit&eacute; de fournir le contenu des documents qu&apos;il &eacute;dite.&nbsp;</p>
<p>- Disposer des droits n&eacute;cessaires sur les &eacute;l&eacute;ments fournis ci-dessus.&nbsp;</p>
<p>- Collaborer activement &agrave; la r&eacute;ussite du projet en apportant au Prestataire dans les d&eacute;lais pr&eacute;alablement d&eacute;finis toutes les informations et tous les documents n&eacute;cessaires &agrave; la bonne appr&eacute;hension des besoins et &agrave; la bonne ex&eacute;cution des Services.&nbsp;</p>
<p>- Se conformer strictement aux pr&eacute;conisations techniques et aux suggestions artistiques faites par le Prestataire.&nbsp;</p>
<p>- Garantir le Prestataire contre toute action qui pourrait lui &ecirc;tre intent&eacute;e du fait du caract&egrave;re des donn&eacute;es ou informations (textes, images, sons) qui auraient &eacute;t&eacute; fournies ou choisies par le Client.&nbsp;</p>
<p>- R&eacute;gler dans les d&eacute;lais pr&eacute;d&eacute;finis dans le devis et dans les pr&eacute;sentes CGV, les sommes dues au Prestataire.&nbsp;</p>
<p>- Informer le Prestataire d&rsquo;une &eacute;ventuelle mise en concurrence avec d&rsquo;autres prestataires.&nbsp;</p>
<p>- S&rsquo;assurer de la mise &agrave; disposition de tous les moyens n&eacute;cessaires pour permettre au Prestataire de r&eacute;aliser les Services dans ses locaux et/ou &agrave; distance.&nbsp;</p>
<p><br></p>
<p>Avant chaque intervention du Prestataire, le Client s&rsquo;engage &agrave; r&eacute;aliser toutes les proc&eacute;dures de sauvegarde n&eacute;cessaires &agrave; la protection et &agrave; la sauvegarde de ses donn&eacute;es, programmes et fichiers informatiques.&nbsp;</p>
<p><br></p>
<p>Enfin, le Client fait son affaire et est seul responsable des lois et r&eacute;glementations applicables aux Services notamment au regard de la protection des droits de propri&eacute;t&eacute; intellectuelle, mentions l&eacute;gales, protection des donn&eacute;es personnelles, protection des mineurs (si applicable) et droit de la consommation (si applicable).</p>
<p><br></p>
<ul>
    <li>Obligations du Prestataire</li>
</ul>
<p><br></p>
<p>Dans le cadre des pr&eacute;sentes CGV et la r&eacute;alisation des Services, le Prestataire s&rsquo;engage &agrave; se donner tous les moyens n&eacute;cessaires et &agrave; mettre tout en &oelig;uvre pour la r&eacute;alisation de sa mission dans les r&egrave;gles de l&rsquo;art.&nbsp;</p>
<p>Cette obligation ne saurait constituer une obligation de r&eacute;sultat, le Prestataire ne fournissant les Services que dans le cadre d&rsquo;une obligation de moyen.&nbsp;</p>
<p>- Le Prestataire garantit que les cr&eacute;ations sont juridiquement disponibles et ne sont pas grev&eacute;es de droit des tiers pour les utilisations pr&eacute;vues au titre du Contrat.&nbsp;</p>
<p>- Le Prestataire s&rsquo;engage &agrave; informer de mani&egrave;re r&eacute;guli&egrave;re le Client de l&rsquo;avanc&eacute;e de la r&eacute;alisation du projet et ce, notamment, au travers de validations soumises au Client dans le planning tel que pr&eacute;vu dans le devis.</p>
<p><br></p>
<p><strong>Article 5. Modalit&eacute;s d&rsquo;ex&eacute;cution des Services et livraison des livrables&nbsp;</strong></p>
<p><br></p>
<p>Le Client s&rsquo;engage &agrave; fournir au Prestataire sous forme exploitable l&rsquo;ensemble des documents n&eacute;cessaires &agrave; la r&eacute;alisation des Services confi&eacute;s au Prestataire.&nbsp;</p>
<p><br></p>
<p>Toute modification ult&eacute;rieure ou demande compl&eacute;mentaire demand&eacute;e par le Client fera l&rsquo;objet d&rsquo;une facturation suppl&eacute;mentaire.&nbsp;</p>
<p>La r&eacute;alisation des Services confi&eacute;s au Prestataire d&eacute;pendant directement du respect par le Client de ses propres obligations, les Parties reconnaissent express&eacute;ment que les d&eacute;lais de livraison vis&eacute;s au devis sont donn&eacute;s &agrave; titre purement indicatif et sans garantie.&nbsp;</p>
<p>Un retard sur les d&eacute;lais indiqu&eacute;s ne pourra donc donner lieu au paiement de dommages et int&eacute;r&ecirc;ts, ni autoriser le Client &agrave; r&eacute;silier le Contrat o&ugrave; &agrave; refuser la livraison des Services.</p>
<p><br></p>
<p><strong>Article 6. Prix&nbsp;</strong></p>
<p><br></p>
<p>Les conditions tarifaires du Prestataire relatives &agrave; la fourniture des Services sont pr&eacute;vues dans le devis du Prestataire.</p>
<p>&nbsp;Les prix sont donn&eacute;s &agrave; titre indicatif et sont donc susceptibles de variation. Le prix factur&eacute; est celui pr&eacute;vu dans la Commande valid&eacute;e par le Prestataire.&nbsp;</p>
<p><br></p>
<p>Les prix des Services sont exprim&eacute;s et payables en Euros et sont exprim&eacute;s hors taxe sur la valeur ajout&eacute;e et hors toute autre taxe, le Client &eacute;tant responsable du paiement desdites taxes.&nbsp;</p>
<p>Les prix des Services n&rsquo;incluent pas les &eacute;ventuels frais de d&eacute;placement ou d&rsquo;h&eacute;bergement qui pourraient &ecirc;tre factur&eacute;s en sus par le Prestataire au Client selon les modalit&eacute;s indiqu&eacute;es dans le devis.&nbsp;</p>
<p><br></p>
<p><strong>Article 7. Conditions de paiement</strong></p>
<p><br></p>
<p>&nbsp;L&rsquo;acceptation du devis et de la Commande du Client qui en d&eacute;coule, doit s&rsquo;accompagner du paiement d&rsquo;un acompte tel qu&rsquo;indiqu&eacute; dans le devis.&nbsp;</p>
<p>Le versement de l&rsquo;acompte conditionnera la mise en &oelig;uvre des Services. Le paiement de l&rsquo;acompte pourra &ecirc;tre effectu&eacute; sous forme de virement &agrave; l&rsquo;ordre du Prestataire ou par Carte Bancaire.&nbsp;</p>
<p>Le paiement du solde des Services doit &ecirc;tre effectu&eacute; dans un d&eacute;lai de trente (30) jours &agrave; compter de la date de la facture &eacute;mise par le Prestataire et pourra &ecirc;tre effectu&eacute; sous forme de virement &agrave; l&rsquo;ordre du Prestataire ou par Carte Bancaire.&nbsp;</p>
<p><br></p>
<p><strong>Article 8. P&eacute;nalit&eacute;s de retard&nbsp;</strong></p>
<p><br></p>
<p>En cas de non-paiement &agrave; l&rsquo;&eacute;ch&eacute;ance, toute somme due fera courir des p&eacute;nalit&eacute;s de retard.&nbsp;</p>
<p>Celles-ci courent &agrave; compter du jour suivant la date d&rsquo;&eacute;ch&eacute;ance figurant sur la facture et jusqu&rsquo;au jour du paiement effectif et int&eacute;gral de la somme. Le taux des p&eacute;nalit&eacute;s de retard est fix&eacute; &agrave; trois fois le taux d&rsquo;int&eacute;r&ecirc;t l&eacute;gal en vigueur.&nbsp;</p>
<p>Ces p&eacute;nalit&eacute;s de retard sont exigibles de plein droit et sans qu&rsquo;un rappel par le Prestataire ne soit n&eacute;cessaire.</p>
<p><br></p>
<p>Le Client sera &eacute;galement d&eacute;biteur de plein droit d&rsquo;une indemnit&eacute; forfaitaire minimum de recouvrement de quarante (40) euros des sommes dues par le Client au Prestataire.&nbsp;</p>
<p><br></p>
<p>Enfin, en cas de retard de paiement, le Prestataire se r&eacute;serve le droit de suspendre ou de surseoir &agrave; l&rsquo;ex&eacute;cution des Services pr&eacute;vus dans la Commande dont le paiement fait l&rsquo;objet du retard.&nbsp;</p>
<p><br></p>
<p><strong>Article 9. Frais Annexes&nbsp;</strong></p>
<p><br></p>
<p>Les divers &eacute;l&eacute;ments &eacute;ventuellement n&eacute;cessaires &agrave; la r&eacute;alisation des Services du Prestataire et ne relevant pas de ses offres ne sont pas compris dans les prix indiqu&eacute;s.&nbsp;</p>
<p>Sont &agrave; facturer en sus : les modifications demand&eacute;es par le Client en cours de r&eacute;alisation, si elles impliquent un remaniement du projet.&nbsp;</p>
<p><br></p>
<p><strong>Article 10. R&eacute;siliation&nbsp;</strong></p>
<p><br></p>
<p>Le Prestataire peut mettre fin aux Services fournis au Client en cas de manquement par le Client &agrave; ses obligations au titre du Contrat, non r&eacute;par&eacute; dans un d&eacute;lai de quinze (15) jours &agrave; compter de la notification par le Prestataire de ce manquement par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception, ind&eacute;pendamment de la possibilit&eacute; pour le Prestataire de demander le paiement de dommages et int&eacute;r&ecirc;ts.&nbsp;</p>
<p><br></p>
<p>Le Prestataire peut &eacute;galement mettre fin au Contrat en cas de non-paiement de la ou des facture(s) non acquitt&eacute;e(s) par le Client.&nbsp;</p>
<p><br></p>
<p>En cas de rupture du Contrat avant son terme par le Client, celui-ci s&rsquo;engage formellement &agrave; r&eacute;gulariser et r&eacute;tribuer les montants relatifs au calendrier en cours, aux postes r&eacute;alis&eacute;s ou en cours de r&eacute;alisation, ainsi qu&rsquo;aux services compl&eacute;mentaires effectu&eacute;s. Les fichiers et donn&eacute;es sources cr&eacute;&eacute;s et utilis&eacute;s par le Prestataire ne sauraient d&egrave;s lors &ecirc;tre revendiqu&eacute;s par le Client sans une contribution financi&egrave;re. L&rsquo;acompte d&eacute;j&agrave; vers&eacute; restera acquis par le Prestataire, constituant un d&eacute;dommagement pour le travail entrepris.&nbsp;</p>
<p><br></p>
<p><strong>Article 11. Propri&eacute;t&eacute; intellectuelle&nbsp;</strong></p>
<p><br></p>
<p>Les logiciels, donn&eacute;es, documentations, proc&eacute;d&eacute;s, m&eacute;thodologies, technologies et documents appartenant au Prestataire (ci-apr&egrave;s &laquo; Droits de Propri&eacute;t&eacute; Intellectuelle &raquo;) utilis&eacute;s dans le cadre de la mise en &oelig;uvre des Services restent la propri&eacute;t&eacute; exclusive du Prestataire.&nbsp;</p>
<p>Le Prestataire conc&egrave;de au Client, le cas &eacute;ch&eacute;ant et dans la limite strictement n&eacute;cessaire &agrave; l&rsquo;ex&eacute;cution des Services, &agrave; titre personnel, non exclusif et nontransf&eacute;rable, le droit d&apos;utiliser lesdits Droits de Propri&eacute;t&eacute; Intellectuelle pour la dur&eacute;e de r&eacute;alisation des Services.</p>
<p><br></p>
<p>Les &oelig;uvres cr&eacute;&eacute;es par le Prestataire pour le Client, dans le cadre de l&rsquo;ex&eacute;cution du Contrat, demeurent la propri&eacute;t&eacute; enti&egrave;re et exclusive du Prestataire tant que les factures &eacute;mises par le Prestataire ne sont pas enti&egrave;rement acquitt&eacute;es par le Client.&nbsp;</p>
<p><br></p>
<p>Apr&egrave;s encaissement des factures par le Prestataire, celui-ci c&egrave;de au Client l&rsquo;ensemble des droits de propri&eacute;t&eacute; intellectuelle sur les &oelig;uvres cr&eacute;&eacute;es sp&eacute;cifiquement et &agrave; la demande du Client, dans le cadre de l&rsquo;ex&eacute;cution du Contrat, pour leur dur&eacute;e de protection et pour le monde entier.&nbsp;</p>
<p><br></p>
<p>En particulier, le Prestataire c&egrave;de au Client les droits suivants :&nbsp;</p>
<p>- Le droit de reproduction comprend notamment, et de mani&egrave;re non exhaustive : &minus; Le droit de reproduire et/ou faire reproduire les &oelig;uvres cr&eacute;&eacute;es en nombre illimit&eacute;, par tout proc&eacute;d&eacute; et sur tout support actuel ou futur, et nomment graphique, magn&eacute;tique, num&eacute;rique ou &eacute;lectronique (interactif ou non) ;</p>
<p>&minus; Le droit de mettre en circulation et d&rsquo;exploiter les &oelig;uvres cr&eacute;&eacute;es, commercialement ou non, les reproductions ainsi r&eacute;alis&eacute;es, en nombre illimit&eacute;, &agrave; titre gratuit ou on&eacute;reux, et ce quelle qu&rsquo;en soit la destination.&nbsp;</p>
<p><br></p>
<p>Le droit de repr&eacute;sentation comprend notamment, et de mani&egrave;re non exhaustive :&nbsp;</p>
<p>&minus; Le droit de diffuser et de communiquer &agrave; tout public les &eacute;l&eacute;ments, supports, composants des &oelig;uvres cr&eacute;&eacute;es, par tout proc&eacute;d&eacute; de repr&eacute;sentation connu ou inconnu &agrave; ce jour, pour toute utilisation quelle qu&rsquo;elle soit ;&nbsp;</p>
<p>&minus; La diffusion des &oelig;uvres cr&eacute;&eacute;es par tout moyen, notamment par voie hertzienne, c&acirc;ble-satellite ainsi que par tout r&eacute;seau, et plus g&eacute;n&eacute;ralement par tout moyen de transmission de donn&eacute;es num&eacute;ris&eacute;es ou non.&nbsp;</p>
<p><br></p>
<p>Dans le cadre de la fourniture des Services et en tant que de besoin, le Client conc&egrave;de &eacute;galement au Prestataire un droit d&rsquo;utilisation de ses logiciels, donn&eacute;es et documents, &agrave; titre personnel, gratuit, non exclusif et non transf&eacute;rable pour la dur&eacute;e de r&eacute;alisation des Services.&nbsp;</p>
<p><br></p>
<p>Le Client s&rsquo;engage &agrave; obtenir de tous tiers, si besoin est, le droit de conc&eacute;der au Prestataire les droits d&rsquo;utilisation des logiciels, donn&eacute;es et &eacute;quipements appartenant &agrave; ces tiers pour les besoins de la fourniture des Services.&nbsp;</p>
<p><br></p>
<ul>
    <li>Marques et d&eacute;nominations sociales&nbsp;</li>
</ul>
<p><br></p>
<p>Toute utilisation par le Client des d&eacute;nominations sociales, marques et signes distincts appartenant au Prestataire est strictement prohib&eacute;e sauf en cas d&rsquo;accord expr&egrave;s et pr&eacute;alable du Prestataire.&nbsp;</p>
<p><br></p>
<p>En cas d&rsquo;accord expr&egrave;s et pr&eacute;alable du Prestataire, ce dernier conc&egrave;de alors au Client un droit strictement personnel, non exclusif, et non transf&eacute;rable d&apos;utiliser ses d&eacute;nominations sociales, marques et signes distincts, dans le monde entier et pour toute la dur&eacute;e de validit&eacute; du Contrat.</p>
<p><br></p>
<p>Le Prestataire est quant &agrave; lui autoris&eacute; &agrave; utiliser la d&eacute;nomination sociale/la marque du Client dans le cadre de ses activit&eacute;s &agrave; des fins de promotion commerciale. Le Prestataire se r&eacute;serve &eacute;galement le droit de mentionner les r&eacute;alisations effectu&eacute;es pour le Client sur ses documents de communication externe, de publicit&eacute; (site internet, portfolio, etc.) et lors de d&eacute;marchages de prospection commerciale.&nbsp;</p>
<p><br></p>
<ul>
    <li>Droit moral</li>
</ul>
<p><br></p>
<p>Le Prestataire se r&eacute;serve la possibilit&eacute; d&rsquo;inclure dans la r&eacute;alisation des Services une mention commerciale indiquant clairement sa contribution, assortie lorsque le support le permet d&rsquo;un lien hypertexte pointant vers le site Internet et tout support de communication du Prestataire.&nbsp;</p>
<p><br></p>
<ul>
    <li>Garantie d&rsquo;&eacute;viction&nbsp;</li>
</ul>
<p><br></p>
<p>Le Prestataire garantit le Client contre toute action, r&eacute;clamation, revendication ou opposition de la part de toute personne invoquant un droit de propri&eacute;t&eacute; intellectuelle auxquels la fourniture des Services aurait port&eacute; atteinte, sous r&eacute;serve que le Client informe le Prestataire, d&egrave;s qu&rsquo;il en a connaissance, de toute demande, r&eacute;clamation ou instance pr&eacute;sent&eacute;e ou engag&eacute;e pour un tel motif, par voie judiciaire ou extra-judiciaire.&nbsp;</p>
<p><br></p>
<p>Le Client s&rsquo;engage &agrave; apporter au Prestataire, tous les documents et renseignements en sa possession ainsi que toute l&rsquo;assistance requise qui pourraient &ecirc;tre n&eacute;cessaires &agrave; sa d&eacute;fense.&nbsp;</p>
<p><br></p>
<p>En cas d&rsquo;atteinte av&eacute;r&eacute;e aux droits d&rsquo;un tiers, le Prestataire pourra, &agrave; son choix :&nbsp;</p>
<p>- obtenir toute concession de licence ou autorisation pour permettre au Client de continuer &agrave; utiliser les Services ;&nbsp;</p>
<p>- fournir une solution de remplacement permettant au Client de pouvoir utiliser les Services conform&eacute;ment &agrave; la Commande ;&nbsp;</p>
<p>- si aucune des deux possibilit&eacute;s n&rsquo;est r&eacute;alisable, rembourser le Client des sommes vers&eacute;es au titre des Services, d&eacute;duction faite des sommes d&eacute;j&agrave; pay&eacute;es par le Client pour la p&eacute;riode d&rsquo;utilisation effective des Services.&nbsp;</p>
<p><br></p>
<p>Le Prestataire n&apos;aura aucune obligation d&rsquo;indemnisation ou autre obligation au titre d&rsquo;une action en contrefa&ccedil;on ayant pour origine (a) une utilisation des Services autrement que conform&eacute;ment au Contrat, (b) une combinaison des Services avec d&rsquo;autres services ou mat&eacute;riels non fournis par le Prestataire.&nbsp;</p>
<p><br></p>
<p>Concernant les logiciels, donn&eacute;es ou documents utilis&eacute;s par le Prestataire dans le cadre de la fourniture des Services, dont le Client a acquis les droits d&rsquo;utilisation aupr&egrave;s de tiers ou dont il est propri&eacute;taire, le Client garantit le Prestataire de toutes les cons&eacute;quences ou suites dommageables que le Prestataire aurait &agrave; subir au titre de l&rsquo;utilisation desdits logiciels, donn&eacute;es ou documents contre toute action de la part d&rsquo;une personne revendiquant un droit de propri&eacute;t&eacute; intellectuelle ou se fondant sur une demande en concurrence d&eacute;loyale et/ou parasitisme sur ces logiciels, donn&eacute;es ou documents.</p>
<p><br></p>
<p><strong>Article 12. Garanties&nbsp;</strong></p>
<p><br></p>
<p>Le Prestataire garantit que les Services sont fournis de mani&egrave;re substantiellement conforme &agrave; la Commande.&nbsp;</p>
<p><br></p>
<p>Sauf disposition l&eacute;gale contraire, toute autre garantie, expresse ou implicite est exclue.&nbsp;</p>
<p>Le Prestataire ne peut &ecirc;tre tenu d&rsquo;aucune garantie notamment lorsque le Client a modifi&eacute; ou fait modifier les Services ou a utilis&eacute; d&rsquo;autres services que les Services fournis par le Prestataire, sans son accord pr&eacute;alable et &eacute;crit ou lorsque le Client ou des tiers sont intervenus sur les &eacute;l&eacute;ments des Services sans l&rsquo;accord pr&eacute;alable du Prestataire.&nbsp;</p>
<p><br></p>
<p><strong>Article 13. Responsabilit&eacute;s&nbsp;</strong></p>
<p><br></p>
<p>La responsabilit&eacute; du Prestataire est limit&eacute;e aux seuls dommages directs et r&eacute;sultant d&apos;un d&eacute;faut des Services ou de la violation du Contrat, m&ecirc;me si le d&eacute;faut en question &eacute;tait pr&eacute;visible au moment de la Commande.&nbsp;</p>
<p><br></p>
<p>En aucun cas, le Prestataire ne sera tenu pour responsable des dommages indirects, accessoires ou particuliers tels que d&eacute;finis par la jurisprudence des tribunaux fran&ccedil;ais, notamment, le co&ucirc;t de l&rsquo;obtention de services de substitution, pertes de b&eacute;n&eacute;fices, de donn&eacute;es ou p&eacute;riodes d&rsquo;immobilisation, que sa responsabilit&eacute; soit contractuelle ou d&eacute;lictuelle et qu&rsquo;elle ait ou non son fondement dans l&rsquo;utilisation ou le fonctionnement des Services, m&ecirc;me si le Prestataire a averti le Client de la possibilit&eacute; de tels dommages.&nbsp;</p>
<p><br></p>
<p>En cas de manquement quelconque du Prestataire dans l&rsquo;ex&eacute;cution de ses obligations (d&eacute;faut d&rsquo;ex&eacute;cution ou mauvaise ex&eacute;cution), le Client devra en faire part &agrave; celui-ci dans le d&eacute;lai de huit (8) jours ouvr&eacute;s &agrave; compter de la constatation du manquement par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception.&nbsp;</p>
<p>A d&eacute;faut, le manquement sera inopposable au Prestataire.&nbsp;</p>
<p><br></p>
<p>Dans l&rsquo;hypoth&egrave;se o&ugrave; le Client aurait sign&eacute; un proc&egrave;s-verbal de r&eacute;ception des Services et/ou la maquette de la ou les cr&eacute;ations ou a valid&eacute; la r&eacute;ception des Services et/ou la maquette, par tout moyen et notamment par l&rsquo;utilisation des Services et/ou la maquette, le Prestataire sera r&eacute;put&eacute; avoir r&eacute;alis&eacute; ses obligations de mani&egrave;re conforme au Contrat. Le Client sera alors r&eacute;put&eacute; avoir renonc&eacute; irr&eacute;vocablement &agrave; toutes r&eacute;clamation de plein droit &agrave; ce titre.&nbsp;</p>
<p><br></p>
<p>Par ailleurs, le Prestataire ne peut &ecirc;tre tenu pour responsable de l&rsquo;inex&eacute;cution du Contrat en cas de force majeure telle que d&eacute;finie &agrave; l&rsquo;article 15, et en cas de dommages du fait d&rsquo;un tiers ou imputables &agrave; une mauvaise utilisation ou une utilisation non-conforme par le Client des Services, en violation des prescriptions du Prestataire ou des r&egrave;gles de l&rsquo;art. Hormis les dommages corporels ou d&eacute;c&egrave;s, et sauf en cas de n&eacute;gligence grossi&egrave;re ou de faute intentionnelle causant un dommage direct prouv&eacute; ou en cas de manquement &agrave; une obligation essentielle du Contrat la vidant de sa substance, le Client reconna&icirc;t que la responsabilit&eacute; du Prestataire est limit&eacute;e au montant vers&eacute; pour les Services en cause.</p>
<p><br></p>
<p><strong>Article 14. Force Majeure&nbsp;</strong></p>
<p><br></p>
<p>Les Parties ne peuvent &ecirc;tre consid&eacute;r&eacute;es comme responsables ou ayant failli &agrave; leurs obligations contractuelles, lorsque le d&eacute;faut d&apos;ex&eacute;cution des obligations respectives a pour origine la force majeure telle que d&eacute;finie par la jurisprudence des tribunaux fran&ccedil;ais. Le Contrat entre les parties est suspendu jusqu&apos;&agrave; l&apos;extinction des causes ayant engendr&eacute;es la force majeure. La force majeure prend en compte des faits ou circonstances irr&eacute;sistibles, ext&eacute;rieurs aux parties, impr&eacute;visibles et ind&eacute;pendants de la volont&eacute; des parties, malgr&eacute; tous les efforts raisonnablement possibles pour les emp&ecirc;cher.&nbsp;</p>
<p><br></p>
<p>La Partie touch&eacute;e par un &eacute;v&eacute;nement de force majeure en avisera l&apos;autre Partie dans les cinq (5) jours ouvrables suivant la date &agrave; laquelle elle en aura eu connaissance. Les deux Parties conviendront alors des conditions dans lesquelles l&apos;ex&eacute;cution du Contrat sera poursuivie.&nbsp;</p>
<p><br></p>
<p><strong>Article 15. Travail dissimul&eacute;&nbsp;</strong></p>
<p><br></p>
<p>Le Prestataire d&eacute;clare &ecirc;tre immatricul&eacute; au RCS ou R&eacute;pertoire des M&eacute;tiers, ainsi qu&rsquo;aupr&egrave;s de l&rsquo;URSSAF et que ses immatriculations couvrent express&eacute;ment toutes ses activit&eacute;s pour l&rsquo;ex&eacute;cution des Services d&eacute;finis dans le devis et/ou la Commande.&nbsp;</p>
<p><br></p>
<p>Dans le respect des articles L 8221-1 et suivants du Code du travail et conform&eacute;ment &agrave; l&rsquo;article D 8222-5 du m&ecirc;me code, le Prestataire s&rsquo;engage &agrave; remettre au Client lors de la conclusion du Contrat, et tous les six mois jusqu&rsquo;&agrave; la fin de son ex&eacute;cution si le Client en fait la demande, les documents suivants :&nbsp;</p>
<p>● Une copie de l&rsquo;avis d&rsquo;imposition aff&eacute;rent &agrave; la taxe professionnelle,&nbsp;</p>
<p>● Un extrait Kbis attestant de l&rsquo;immatriculation au registre du commerce et des soci&eacute;t&eacute;s,&nbsp;</p>
<p>● Une attestation sur l&rsquo;honneur &eacute;tablie par le Prestataire, certifiant que le travail est r&eacute;alis&eacute; par des salari&eacute;s employ&eacute;s r&eacute;guli&egrave;rement eu &eacute;gard aux articles D.8222-5, D.8222-7 et D.8222-8 du Code du travail.&nbsp;</p>
<p><br></p>
<p><strong>Article 16. Assurance</strong></p>
<p><br></p>
<p>Chacune des Parties s&rsquo;engage &agrave; maintenir en vigueur, pendant toute la dur&eacute;e de r&eacute;alisation du Contrat, aupr&egrave;s d&rsquo;une compagnie d&rsquo;assurance notoirement solvable, une police d&rsquo;assurance garantissant les dommages pouvant survenir &agrave; ses biens et personnel, ainsi qu&rsquo;une police couvrant sa responsabilit&eacute; professionnelle, de mani&egrave;re &agrave; couvrir les cons&eacute;quences p&eacute;cuniaires des dommages corporels, mat&eacute;riels et immat&eacute;riels dont elles auraient &agrave; r&eacute;pondre, caus&eacute;s par tout &eacute;v&eacute;nement et qui serait le fait de ses collaborateurs et/ou soci&eacute;t&eacute;s partenaires &eacute;ventuels lors de l&rsquo;ex&eacute;cution du Contrat.</p>
<p><br></p>
<p><strong>Article 17. Confidentialit&eacute;&nbsp;</strong></p>
<p><br></p>
<p>Chaque Partie s&rsquo;engage, tant en son propre compte que pour le compte de ses employ&eacute;s et soci&eacute;t&eacute;s partenaires, &agrave; pr&eacute;server la confidentialit&eacute; des informations confidentielles &eacute;chang&eacute;es (les &laquo; Informations Confidentielles &raquo;).&nbsp;</p>
<p>Sont r&eacute;put&eacute;es Informations Confidentielles, toutes les informations quels qu&rsquo;en soient la nature, la forme ou le support, auxquelles chaque Partie aura acc&egrave;s dans le cadre de l&rsquo;ex&eacute;cution du Contrat, et notamment sans que cette liste soit limitative, tous moyens mis &agrave; la disposition du Prestataire au Client et du Client au Prestataire, toute donn&eacute;e technique, industrielle, financi&egrave;re ou commerciale, ou toute autre information et tous documents relatifs aux activit&eacute;s de chaque Partie.&nbsp;</p>
<p>Les Informations Confidentielles ne couvrent pas les documents, donn&eacute;es ou autres informations qui sont :&nbsp;</p>
<p>● connus par l&rsquo;une des Parties sur une base non-confidentielle avant leur divulgation par l&apos;autre Partie ;&nbsp;</p>
<p>● tomb&eacute;s ou qui tomberont dans le domaine public au jour de leur divulgation ;&nbsp;</p>
<p>● l&eacute;gitimement obtenus d&apos;un tiers non tenu par une obligation de confidentialit&eacute; ;&nbsp;</p>
<p>● d&eacute;velopp&eacute;s de fa&ccedil;on ind&eacute;pendante par la Partie r&eacute;ceptrice qui n&rsquo;a eu acc&egrave;s &agrave; aucune information de la Partie divulgatrice ;&nbsp;</p>
<p>● divulgu&eacute;s en vertu d&apos;une disposition l&eacute;gislative ou r&eacute;glementaire.&nbsp;</p>
<p><br></p>
<p>Chaque Partie s&rsquo;engage :&nbsp;</p>
<p>● &agrave; appliquer aux Informations Confidentielles les m&ecirc;mes mesures de protection que celles qu&apos;elle applique &agrave; ses propres informations confidentielles ;&nbsp;</p>
<p>● &agrave; ne communiquer les Informations Confidentielles qu&apos;&agrave; ses seuls employ&eacute;s et collaborateurs amen&eacute;s &agrave; les conna&icirc;tre dans le cadre de la r&eacute;alisation des Services ;&nbsp;</p>
<p>● &agrave; ne pas divulguer, publier ou transmettre &agrave; des tiers les Informations Confidentielles, sous quelque forme que ce soit, sans l&rsquo;accord pr&eacute;alable &eacute;crit de l&rsquo;autre Partie ;</p>
<p>● &agrave; n&apos;utiliser les Informations Confidentielles que pour les seuls besoins de r&eacute;alisation des Services.</p>
<p><br></p>
<p><strong>Article 18. Non sollicitation&nbsp;</strong></p>
<p><br></p>
<p>Chacune des Parties renonce, sauf accord &eacute;crit pr&eacute;alable, &agrave; faire directement ou indirectement des offres d&rsquo;engagement &agrave; un collaborateur de l&rsquo;autre Partie ayant travaill&eacute; dans le cadre des Services, objet du pr&eacute;sent Contrat, ou &agrave; le prendre &agrave; son service, sous quelque statut que ce soit. Cette renonciation est valable pour une dur&eacute;e de deux (2) ans &agrave; compter de la fin du pr&eacute;sent Contrat.&nbsp;</p>
<p><br></p>
<p><strong>Article 19. Donn&eacute;es personnelles&nbsp;</strong></p>
<p><br></p>
<p>Conform&eacute;ment aux dispositions de la Loi Informatique et Libert&eacute;s n&deg; 78-17 du 6 janvier 1978 telle que modifi&eacute;e, le Client dispose de la qualit&eacute; de responsable de traitement dans le cadre de l&rsquo;ex&eacute;cution du Contrat.&nbsp;</p>
<p><br></p>
<p>Le Prestataire agissant au nom et pour le compte du Client dans le traitement des donn&eacute;es personnelles qui lui sont communiqu&eacute;es par le Client, il dispose de la qualit&eacute; de sous-traitant.&nbsp;</p>
<p><br></p>
<p>A ce titre, le Prestataire s&rsquo;engage &agrave; prendre les mesures n&eacute;cessaires afin d&rsquo;assurer la protection, la s&eacute;curit&eacute; et la confidentialit&eacute; des donn&eacute;es personnelles qui lui sont transmises par le Client.&nbsp;</p>
<p><br></p>
<p><strong>Article 20. Modifications des CGV et cession du Contrat&nbsp;</strong></p>
<p><br></p>
<p>Le Prestataire peut d&eacute;cider de c&eacute;der ou transf&eacute;rer les droits ou obligations que lui conf&egrave;re le pr&eacute;sent Contrat sous r&eacute;serve que le Client b&eacute;n&eacute;ficie des Services dans les m&ecirc;mes conditions.&nbsp;</p>
<p><br></p>
<p>Le Prestataire se r&eacute;serve le droit de modifier les pr&eacute;sentes CGV et d&rsquo;en notifier le Client. Si une modification substantielle des termes des CGV n&rsquo;est pas acceptable par le Client, celui-ci dispose d&rsquo;un d&eacute;lai de quinze (15) jours &agrave; compter de la notification par le Prestataire des modifications pour en informer le Prestataire. En cas de d&eacute;saccord des Parties sur ces modifications, les Parties pourront r&eacute;silier le Contrat.&nbsp;</p>
<p><br></p>
<p>A l&rsquo;expiration de ce d&eacute;lai de quinze (15) jours, les modifications des CGV seront d&eacute;finitivement consid&eacute;r&eacute;es comme accept&eacute;es par le Client.&nbsp;</p>
<p><br></p>
<p><strong>Article 21. Droit applicable et juridictions comp&eacute;tentes&nbsp;</strong></p>
<p><br></p>
<p>La loi du Contrat est la Loi Fran&ccedil;aise. Les parties conviennent express&eacute;ment que la Convention de Vienne sur la vente internationale de marchandises en date du 11 avril 1980 n&rsquo;est pas applicable au Contrat.</p>
<p><br></p>
<p>En cas de diff&eacute;rend entre les Parties, celles-ci tenteront de trouver une solution amiable &agrave; ce diff&eacute;rend dans un d&eacute;lai de trente jours &agrave; compter de la notification du diff&eacute;rend de la Partie en demande &agrave; l&rsquo;autre Partie par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception.&nbsp;</p>
<p>A d&eacute;faut de solution amiable trouv&eacute;e entre les Parties, le litige sera soumis &agrave; la connaissance du Tribunal de commerce territorialement comp&eacute;tent.&nbsp;</p>
<p><br></p>
<p><strong>Article 22. Refus&nbsp;</strong></p>
<p><br></p>
<p>Le Prestataire se r&eacute;serve le droit de ne pas accepter une Commande de la part du Client lorsque le Prestataire a d&eacute;j&agrave; rencontr&eacute; des probl&egrave;mes de paiement (nonpaiement ou retard de paiement) avec le Client pour une ou plusieurs Commande(s) pr&eacute;c&eacute;dente(s).&nbsp;</p>
<p><br></p>
<p><strong>Article 23. Sous-traitance&nbsp;</strong></p>
<p><br></p>
<p>Le Prestataire a la facult&eacute; de sous-traiter tout ou partie de la r&eacute;alisation des Services &agrave; des sous-traitants. Dans ce cas, le Prestataire demeure responsable de la r&eacute;alisation des Services vis-&agrave;-vis du Client.&nbsp;</p>
<p><br></p>
<p><strong>Article 24. Dispositions g&eacute;n&eacute;rales&nbsp;</strong></p>
<p><br></p>
<ul>
    <li>Documents ant&eacute;rieurs ou autres accords&nbsp;</li>
</ul>
<p><br></p>
<p>Le Contrat se substitue &agrave; tout autre document ant&eacute;rieur, &agrave; tout autre accord &eacute;crit ou verbal en relation avec le m&ecirc;me objet, &agrave; l&rsquo;exception du devis, de la Commande et pr&eacute;vaut sur toute disposition contraire qui pourrait &ecirc;tre contenue dans les documents &eacute;manant du Client.&nbsp;</p>
<p><br></p>
<ul>
    <li>Autonomie des clauses&nbsp;</li>
</ul>
<p><br></p>
<p>Si l&rsquo;une quelconque disposition de ces CGV ou son application &agrave; toute personne ou circonstance est jug&eacute;e nulle, cette nullit&eacute; ne concernera pas les autres dispositions ou applications de ces CGV, qui resteront en vigueur, s&eacute;par&eacute;ment de la disposition jug&eacute;e nulle. A cette fin, les dispositions de ces CGV sont d&eacute;clar&eacute;es autonomes.&nbsp;</p>
<p><br></p>
<ul>
    <li>Notification&nbsp;</li>
</ul>
<p><br></p>
<p>Toute notification devra &ecirc;tre faite par &eacute;crit et &ecirc;tre soit remise en mains propres, soit adress&eacute;e par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception, soit faite par acte extra judiciaire &agrave; l&rsquo;adresse indiqu&eacute;e dans la commande.</p>
<p><br></p>
<ul>
    <li>Langue du Contrat</li>
</ul>
<p><br></p>
<p>Le Contrat est r&eacute;dig&eacute; en langue fran&ccedil;aise. Une traduction en langue &eacute;trang&egrave;re peut &ecirc;tre fournie &agrave; titre d&rsquo;information. En cas de contradiction, seule la version fran&ccedil;aise fera foi entre les Parties.</p>
</div>`;