import React, { FC, useState } from "react";

type Props = {
  values: [string, string];
  activeValue: string;
  getActiveValue: (value: string) => void;
};

const bgColor = ["transparent", "#0E1B4D"];
const txtColor = ["#0E1B4D", "#FFF"];

export const SwitchInput: FC<Props> = ({
  values,
  activeValue,
  getActiveValue,
}) => {
  const [selectedValue, setSelectedValue] = useState(activeValue);
  return (
    <>
      <div style={styles.container}></div>
      <div
        style={{
          position: "absolute",
          ...styles.bullet,
          backgroundColor: bgColor[1 - values.indexOf(selectedValue)],
        }}
        onClick={() => {
          setSelectedValue(values[0]);
          getActiveValue(values[0]);
        }}
      >
        <div
          style={{
            ...styles.text,
            color: txtColor[1 - values.indexOf(selectedValue)],
          }}
        >
          {values[0]}
        </div>
      </div>
      <div
        style={{
          ...styles.bullet,
          ...styles.leftBullet,
          position: "absolute",
          backgroundColor: bgColor[values.indexOf(selectedValue)],
        }}
      >
        <div
          style={{
            ...styles.text,
            color: txtColor[values.indexOf(selectedValue)],
          }}
          onClick={() => {
            setSelectedValue(values[1]);
            getActiveValue(values[1]);
          }}
        >
          {values[1]}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    width: 190,
    height: 46,
    borderRadius: 45,
    border: "1px solid #0E1B4D",
  },
  bullet: {
    width: 103,
    height: 46,
    borderRadius: 45,
    padding: 1,
  },
  leftBullet: {
    marginLeft: 87,
  },
  text: {
    fontFamily: "Poppins",
    fontSize: 22,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
