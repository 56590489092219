import * as React from 'react';
import ClientInfoScene from './ClientInfoScene';


interface ClientInfoViewProps {
    
}
 
const ClientInfoView: React.FC<ClientInfoViewProps> = () => {
    return ( <ClientInfoScene/> );
}
 
export default ClientInfoView;