import { createSlice } from "@reduxjs/toolkit";
import { ClientState } from "./types";
import {
  addClientAction,
  deleteClientAction,
  editClientProductAction,
  fetchClientsAction,
} from "./thunkActions";

const initialState: ClientState = {
  isLoading: false,
  error: null,
  clients: [],
  productLines: [],
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch clients
      .addCase(fetchClientsAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchClientsAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.clients = payload?.clients ?? [];
        state.productLines = payload?.productLines ?? [];
      })
      .addCase(fetchClientsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      //   edit product for a client
      .addCase(editClientProductAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editClientProductAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(editClientProductAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      //   add new client
    //   .addCase(addClientAction.pending, (state) => {
    //     state.isLoading = true;
    //     state.error = null;
    //   })
    //   .addCase(addClientAction.fulfilled, (state) => {
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(addClientAction.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = action.error.message || null;
    //   })
    //   //   delete user
    //   .addCase(deleteClientAction.pending, (state) => {
    //     state.isLoading = true;
    //     state.error = null;
    //   })
    //   .addCase(deleteClientAction.fulfilled, (state) => {
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(deleteClientAction.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = action.error.message || null;
    //   });
  },
});

export const clientReducer = clientSlice.reducer;
