import React, { CSSProperties, FC, useEffect, useState } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import Kinomo_hologramme from '../../../assets/img/Kinomo_hologramme.png'
import { applications } from '../../../datalayer/applications';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { GallerySlider } from '../../../components/organisms/galery-slider/GalerySlider';
import { fixtures } from './fixtures';
import { CssType } from '../types/commun';
import axios from 'axios';
import { API_URL } from 'config';

interface ApplicationsSeneProps { }

const ApplicationsSene: FC<ApplicationsSeneProps> = () => {
    const [realisation, setRealisation] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        axios.get(`${API_URL}/api/realisations?populate=photo`).then((response) => {
            setRealisation(response.data.data);
        }).catch(() => {
            setRealisation([]);
        }).finally(() => setIsLoading(false))

    }, [])
    const items = realisation.map((item, index) => {
        const { attributes } = item
        const { client, description, title, photo }: any = attributes
        const imageUrl = photo.data.attributes.url
        return {
            img: imageUrl,
            description,
            title: `${client} - ${title}`
        }

    })

    return (<HomeLayout>
        {/* Section 1 */}
        <div style={styles.section1.container}>
            <img src={Kinomo_hologramme} alt="studio_code" style={styles.section1.img} />
            <div style={styles.section1.txt}>
                {fixtures.sectionTitle}
            </div>
        </div>
        {/* Section 2 */}
        <div style={styles.section2.txt} dangerouslySetInnerHTML={{ __html: fixtures.section2Text }}>
        </div>
        {/* Section 3 */}
        <div style={styles.section3.container}>
            {/* {realisation.map((item, index) => ( */}
                <div >
                    {/* <Typography sx={styles.section3.title} variant="h4">{item.type}</Typography>
                    <Typography
                        sx={styles.section3.body}
                        variant="body1">{item.description}</Typography> */}
                    <GallerySlider items={items} />
                </div>
            {/* ))} */}
        </div>
    </HomeLayout >);
}

export default ApplicationsSene;

const styles: CssType = {
    section1: {
        container: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-around'
        },
        img: { width: '100%', height: 'auto' },
        txt: {
            textAlign: 'center',
            position: 'absolute',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 36,
            color: '#FFFFFF',
        },
    },
    section2: {
        txt: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: 18,
            marginTop: 10,
            marginBottom: 10,
            textAlign: 'center',
            color: '#000000',

        },
    },
    section3: {
        container: {
            padding: 40
        },
        title: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 24,
            color: '#0E1B4D',
            marginTop: 5
        },
    }
}