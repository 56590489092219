import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth/reducer";
import { AuthState } from "./auth/types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { ClientState } from "./admin/types";
import { clientReducer } from "./admin/reducer";
import { productLinesReducer } from "./client/gestion/reducer";
import { ProductLinesState } from "./client/gestion/types";

export type RootState = {
  auth: AuthState;
  client: ClientState;
  productLines: ProductLinesState;
};

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
  productLines: productLinesReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
