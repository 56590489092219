import React from 'react';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';

const PersonalInfoForm = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <>
      <h2>Mes informations personnelles</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <TextField
            style={style.field}
            label="Prénom"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={style.field}
            label="Nom de famille"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={isMobile ? 6 : 8}>
          <TextField
            style={style.field}
            label="E-mail"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={style.field}
            label="Numéro de téléphone (faculatif)"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={style.field}
            label="Entreprise (faculatif)"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <h2>Modifier mon mot de passe</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={isMobile ? 6 : 8}>
          <TextField
            type="password"
            label="Ancien mot de passe"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={style.field}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="password"
            label="Nouveau mot de passe"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={style.field}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="password"
            label="Confirmer le nouveau mot de passe"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={style.field}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            sx={{
              background: '#0E1B4D',
              border: '1px solid rgba(14, 27, 77, 0.7)',
              borderRadius: 90,
              marginTop: '20px',
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {
              // submitting ?
              // <CircularProgress size={20} color="inherit" /> :
              'Envoyer la demande'
            }
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const style = {
  field: {
    margin: 10
  }
}

export default PersonalInfoForm;
