import React, { ChangeEvent } from "react";
import {
  Typography,
  FormControl,
  Button,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CounterInput from "components/inputs/couter-input/CounterInput";
import { SwitchInput } from "./SwitchInput";
import { LocalizationProvider, TimePicker, frFR } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProductLinesInputType } from "store/client/gestion/types";
import { updateProductLineAction } from "store/client/gestion/thunkActions";

type Props = {
  id: number;
  productName: string;
  state: boolean | null;
  brightness: number | null;
  startTime: string | null;
  endTime: string | null;
  hasSubscription: boolean;
};

const CustomCard: React.FC<Props> = ({
  id,
  productName,
  state,
  brightness,
  startTime,
  endTime,
  hasSubscription,
}) => {
  const navigate = useNavigate();

  // Format the parsed time if needed
  const [startHour, setStartHour] = React.useState<Dayjs | null>(
    dayjs(new Date(`1970-01-01T${startTime}Z`))
  );
  const [stopHour, setStopHour] = React.useState<Dayjs | null>(
    dayjs(new Date(`1970-01-01T${endTime}Z`))
  );
  const dispatch = useDispatch();
  const handleUpdate = (input: ProductLinesInputType) => {
    dispatch(
      updateProductLineAction({
        id,
        input,
      }) as any
    );
  };
  return (
    <Container style={{ padding: 30 }}>
      <Typography
        variant="h5"
        gutterBottom
        style={{
          color: "#0E1B4D",
          fontSize: 28,
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        {productName}
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControl>
            <SwitchInput
              values={["ON", "OFF"]}
              activeValue={state ? "ON" : "OFF"}
              getActiveValue={(value) => {
                handleUpdate({
                  state: value === "ON",
                });
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <div>
            <div style={{ padding: 15, textDecorationLine: "underline" }}>
              Minuterie :
            </div>

            <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <div>Heure de démarrage</div>
                <TimePicker
                  sx={{ width: 140 }}
                  value={startHour}
                  onChange={(value) => {
                    setStartHour(value);
                    handleUpdate({
                      startTime: value?.format("HH:mm:ss.SSS"),
                    });
                  }}
                  referenceDate={dayjs(new Date())}
                  minutesStep={30}
                />
              </div>
            </LocalizationProvider>
            <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div>Heure d’arrêt</div>
                <TimePicker
                  sx={{ width: 140 }}
                  value={stopHour}
                  onChange={(value) => {
                    handleUpdate({
                      startTime: value?.format("HH:mm:ss.SSS"),
                    });
                    setStopHour(value);
                  }}
                  referenceDate={dayjs(new Date())}
                  minutesStep={30}
                />
              </div>
            </LocalizationProvider>
          </div>
        </Grid>

        <Grid item>
          <Grid item xs={12}>
            <div style={{ padding: 15 }}>
              <CounterInput
                name="helios_qte"
                min={1}
                max={15}
                value={brightness ?? 0}
                onChange={(_, value) => {
                  handleUpdate({
                    brightness: value,
                  });
                }}
                label="Luminosité : "
              />
            </div>
          </Grid>
        </Grid>
        <Grid alignSelf={"center"} item>
          {/* <Button
            sx={{
              background: "#0E1B4D",
              border: "1px solid rgba(14, 27, 77, 0.7)",
              borderRadius: 90,
              margin: "0 50",
              fontFamily: "Poppins",
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {"Vidéo 3D personnalisée"}
          </Button> */}
        </Grid>
        <Grid alignSelf={"center"} item>
          <Button
            disabled={!hasSubscription}
            sx={{
              background: "#0E1B4D",
              border: "1px solid rgba(14, 27, 77, 0.7)",
              borderRadius: 90,
              margin: "0 50",
              fontFamily: "Poppins",
            }}
            type="submit"
            variant="contained"
            color="primary"
            // fullWidth
            // style={styles.submitButton}
            onClick={() => {
              navigate("/support");
            }}
          >
            {
              // submitting ?
              // <CircularProgress size={20} color="inherit" /> :
              "Assistance"
            }
          </Button>
        </Grid>
        {!hasSubscription && (
          <span
            style={{
              textAlign: "center",
              fontFamily: "Poppings",
              fontSize: 14,
              marginTop: 15,
            }}
          >
            Pour y souscrire, <Link to="/support">cliquez ici.</Link>
          </span>
        )}
      </Grid>
    </Container>
  );
};

export default CustomCard;
