import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css'
import { API_URL } from 'config';
interface Props {
    items: any
}
export const GallerySlider: FC<Props> = ({ items }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: isMobile ? 1 : 1,
        slidesToScroll: 1
    };
    return (
        <div >
            <Slider  {...sliderSettings} autoplay autoplaySpeed={4000}>
                {items.map((item: any, index: number) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>

                            <img src={`${API_URL}${item.img}`} alt={item.title} style={{ maxWidth: '40%', marginBottom: 10 }} />
                            <Typography
                                style={{
                                    marginTop: 2,
                                    marginBottom: 5,
                                    fontFamily: 'Poppins',
                                    fontStyle: 'italic',
                                    textAlign: 'center',
                                    maxWidth: isMobile ? '100%' :'60%',
                                }}
                                variant="body1">{`"${item.description}"`}</Typography>
                            <Typography variant="h6" align="center">{item.title}</Typography>
                        </div>

                    </div>
                ))}
            </Slider >
        </div>
    );
};