import React, { FC } from 'react';
import StudioScene from './StudioScene';
interface StudioLayoutProps {

}

const StudioLayout: FC<StudioLayoutProps> = () => {
    return (<>
        <StudioScene />
    </>);
}

export default StudioLayout;