import React, { FC, PropsWithChildren } from "react";
import Header from "../commun/header/Header";
import { menuItems } from "../../datalayer/commun/header";
import Footer from "../commun/footer/Footer";

interface HomeLayoutProps {}

const HomeLayout: FC<PropsWithChildren<HomeLayoutProps>> = ({ children }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div style={{ flex: 1 }}>
          <Header menuItems={menuItems} />
          {children}
        </div>
        <div style={{ flexShrink: 0 }}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HomeLayout;
