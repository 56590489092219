import { Container } from '@mui/material';
import * as React from 'react';
import PersonalInfoForm from './components/PersonalInfoForm';
import HomeLayout from '../../../components/layouts/HomeLayout';


interface ClientInfoSceneProps {

}

const ClientInfoScene: React.FC<ClientInfoSceneProps> = () => {
    return (<>
        <HomeLayout>
            <Container maxWidth="sm">
                <PersonalInfoForm />
                <div style={{ cursor: 'pointer', margin: 10 }}>
                    Supprimer mon compte et mes données
                </div>
            </Container>
        </HomeLayout>
    </>);
}

export default ClientInfoScene;