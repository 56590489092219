import React, { FC } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import { privacyPolicy } from '../../../datalayer/legal/privacyPolicy';

interface PrivacyPolicyProps {

}

const PrivacyPolicy: FC<PrivacyPolicyProps> = () => {
    return (<HomeLayout>
        <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
    </HomeLayout>);
}

export default PrivacyPolicy;