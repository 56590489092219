import React, { ComponentType, FC } from 'react';
import { Navigate } from 'react-router-dom';

type PrivateComponentProps = {
    roles: string[]; // Array of allowed roles
    userRole: string; // Current user's role
    component: any
}
const PrivateComponent: FC<PrivateComponentProps> = ({
    component: Component,
    roles,
    userRole,
}) => {
    if (!roles.includes(userRole))
        return <Navigate to='/' />;
    return <Component />
};

export default PrivateComponent;
