import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import Switch from '../../../components/switch/Switch';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from 'react-router-dom';
import ContactForm from '../../../components/forms/contact-form/ContactForm';
import { products } from '../../../datalayer/Products';
import Helios from '../../../assets/videos/HOL_Helios_v0050.mp4'
import Solaris from '../../../assets/videos/HOL_Solaris_v0030.mp4'
import { API_URL } from 'config';

interface ProductsSceneProps {

}
const pdfBrochure: any = {
    Solaris: `${API_URL}/uploads/One_pager_Solaris_6d3d713805.pdf`,
    Helios: `${API_URL}/uploads/One_Pager_Helios_6a3c9bd8e5.pdf`,
}

const ProductsScene: FC<ProductsSceneProps> = () => {
    const [selectedProduct, setSelectedProduct] = useState(products[0].name);
    const product = products.filter((p: { name: any; }) => p.name === selectedProduct)[0]
    const style = useStyles();
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openModal, setOpenModal] = useState(false);
    const contactFormRef = useRef<any>(null);
    const { target } = useParams()
    useEffect(() => {
        if (target === 'contact') window.scrollTo({
            top: document.body.scrollHeight,
            // behavior: 'smooth'
        })
    }, []);
    const renderModal = useCallback(() => {
        return <div>
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>Demander la brochure de présentation</DialogTitle>
                <DialogContent>
                    <ContactForm ref={contactFormRef} withCoordinates={false} withTitle={false} successCallback={() => {
                        window.open(`${pdfBrochure[selectedProduct]}`, 'Download');
                        setOpenModal(false);
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}>Annuler</Button>
                    <Button onClick={() => {
                        contactFormRef?.current?.submitForm()
                    }}>Télécharger</Button>
                </DialogActions>
            </Dialog>
        </div>
    }, [openModal, contactFormRef, contactFormRef?.current, selectedProduct])

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    let cbgcolor = '#0a1d56';
    if (/iPhone/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent)) {
        cbgcolor = '#0D1B4C'
    }

    return (
        <HomeLayout >
            <div style={{ backgroundColor: cbgcolor, height: '100hv' }}>
                {/* Section 1 */}
                <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'start' }}>
                    <Switch
                        values={products.map((product: { name: any; }) => product.name)}
                        activeValue={setSelectedProduct}
                    />
                </div>
                <Grid container justifyContent="center" alignItems="center" >
                    <Grid xs={12} md={6} >
                        {selectedProduct === 'Helios' ? <> <video
                            playsInline
                            ref={videoRef}
                            autoPlay
                            loop
                            muted
                            style={{
                                width: '100%', // Set the desired width
                                height: 'auto', // Adjust the height accordingly
                                objectFit: 'cover', // Maintain aspect ratio and cover parent container
                            }}>
                            <source src={Helios} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        </> :
                            <video
                                playsInline
                                ref={videoRef}
                                autoPlay
                                loop
                                muted
                                style={{
                                    width: '100%', // Set the desired width
                                    height: 'auto', // Adjust the height accordingly
                                    objectFit: 'cover', // Maintain aspect ratio and cover parent container
                                }}>
                                <source src={Solaris} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>}
                    </Grid>
                </Grid>
            </div>
            {/* Section 2 */}
            <div style={style.section}>
                <div style={{ ...style.section2Container, ...{ textAlign: 'center' } }}>{product.name}</div>
                <TableContainer style={style.tableContainer}>
                    <Table style={style.table}>
                        <TableBody >
                            {Object.keys(product.caracteristics).map((key: string) => (
                                <TableRow>
                                    <TableCell style={{ ...style.tableCellLeft, ...{ fontFamily: 'Poppins', textAlign: 'center' } }}>{key}</TableCell>
                                    <TableCell style={{ ...style.tableCellRight, ...{ fontFamily: 'Poppins', } }}>{(product.caracteristics as any)[key] as any}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Box sx={style.box}>
                <Button onClick={() => { setOpenModal(true) }} variant="outlined" sx={{ fontFamily: 'Poppins', }} color={'inherit'} >
                    {'Brochure de présentation'}
                </Button>
            </Box>
            {/* Section 3 */}
            <div style={{
                ...style.section3Container,
                marginLeft: isMobile ? 30 : 150,
                marginRight: isMobile ? 30 : 150,
                marginBottom: isMobile ? 15 : 50,
            }}>
                <h2>Une gestion simplifiée</h2>
                <p>Pour allumer/éteindre, régler la luminosité, ajouter/supprimer des vidéos ou simplement sélectionner la vidéo à diffuser sur votre support <b>{product.name}</b>, plusieurs solutions sont possibles:</p>
                <ul>
                    <li>Une interface en ligne pour prendre le contrôle de son panneau, qui permet aussi aux équipes Starget Holographics d’intervenir à distance selon les besoins du client.</li>
                    <li>Un écran tactile intégré au support, sur lequel le client peut insérer du contenu par clé USB.</li>
                </ul>
                <p>Pour la création de contenu à diffuser, Starget Holographics vous fournira les caractéristiques exactes des vidéos à diffuser sur votre panneau.</p>
                <p>L’équipe du Studio est également disponible pour créer votre contenu personnalisé.</p>
                <p>Pour commander votre contenu à diffuser, rendez-vous sur la page Studio.</p>
                <Box sx={style.section3Box}>
                    <Button variant="outlined" sx={{ fontFamily: 'Poppins', }} onClick={() => { navigate('/studio') }} color={'inherit'} endIcon={<ArrowForwardIcon />}>
                        {'Studio'}
                    </Button>
                </Box>
            </div>

            {/* Section 4 */}
            <div style={{
                ...style.section4Container,
                marginLeft: isMobile ? 30 : 150,
                marginRight: isMobile ? 30 : 150,
                marginBottom: isMobile ? 15 : 50,
            }}>
                <h2> Déléguez la gestion de vos supports</h2>
                <p>Sur votre interface de gestion, vous pouvez souscrire à un abonnement premium vous accordant les avantages suivants :</p>
                <ul>
                    <li>Une assistance prioritaire avec une prise de contrôle de vos supports à distance.</li>
                    <li>La possibilité de déléguer la gestion de vos supports aux équipes Starget Holographics.</li>
                    <li>L’accès à une banque en ligne de visuels 3D pour vos supports.</li>
                    <li>Une remise de 20% sur toutes les créations de visuels 3D personnalisés.</li>
                </ul>
            </div>
            {/* Contact form */}
            {renderModal()}
            <div>
                <ContactForm />
            </div>
        </HomeLayout >
    );
}
export default ProductsScene;

const useStyles = () => ({
    section: {
        // background: '#0E1B4D',
        padding: '20px'
    },
    table: {
        color: 'white',
        border: '1px solid #0E1B4D',
        alignSelf: 'center',
        maxWidth: 700,

    },
    tableContainer: {
        alignSelf: 'center',
        margin: '0 auto',
        maxWidth: 700
    },
    sectionContainer: { display: 'flex', alignItems: 'center' },
    sectionRow: { flex: 1 },
    section2Container: {
        color: '#0E1B4D',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '25px',
    },
    tableCellLeft: { color: '#0E1B4D', borderRight: '1px solid #0E1B4D', borderBottom: '1px solid #0E1B4D', textAlign: 'center' },
    tableCellRight: { color: '#0E1B4D', borderBottom: '1px solid #0E1B4D', },
    box: { marginTop: 5, display: 'flex', justifyContent: 'center' },
    section3Container: {
        alignSelf: 'center',
    },
    section3Box: { margin: 5, display: 'flex', justifyContent: 'flex-end' },
    section4Container: {
        alignSelf: 'center',
        marginLeft: 150,
        marginRight: 150,
        marginBottom: 50,
    },
});