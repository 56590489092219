import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  fetchProductLines,
  updateProductLines,
} from "./services";
import { ProductLinesInputType } from "./types";

export const fetchProductLinesAction = createAsyncThunk(
  "clients/get-product-lines",
  async (_, { getState }) => {
    try {
      const { auth } = getState() as RootState;
      // Your API call logic here using Axios
      const response = (await fetchProductLines(auth.token ?? "")) as any;
      return response.data;
    } catch {}
  }
);

export const updateProductLineAction = createAsyncThunk(
  "clients/put-product-lines",
  async (
    { id, input }: { id: number; input: ProductLinesInputType },
    { getState }
  ) => {
    try {
      const { auth } = getState() as RootState;
      // Your API call logic here using Axios
      const response = (await updateProductLines(
        auth.token ?? "",
        id,
        input
      )) as any;
      return response.data;
    } catch {}
  }
);
