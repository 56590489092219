export const menuItems = [
    {
        text: 'Nos produits',
        path: '/produits'
    },
    {
        text: 'Commander',
        path: '/commander'
    },
    {
        text: 'Studio',
        path: '/studio'
    },
    {
        text: 'Réalisations',
        path: '/realisations'
    },
]