import React, { FC, useEffect, useRef } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import SimpleCard from '../../../components/cards/simple-card/SimpleCard';
import { sections, slider } from '../../../datalayer/home';
// import myVideo from '../../../assets/videos/HOL_HomePage_v0020.mp4'
import myVideo from '../../../assets/videos/HOL_HomePage_v0023.mp4'
import expo from '../../../assets/img/expo.png'
import aeroport from '../../../assets/img/aeroport.png'
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
// import VerticalSlider from '../../components/organisms/slider/VerticalSlider';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import './style.css'
import './smoothSlider.css'
import { CssType } from '../types/commun';
import VerticalSlider from 'components/organisms/slider/VerticalSlider';
import Footer from 'components/commun/footer/Footer';
import Header from 'components/commun/header/Header';
import { menuItems } from 'datalayer/commun/header';
import SimpleFooter from 'components/commun/footer/SimpleFooter';
interface HomeSceneProps { }

const HomeScene: FC<HomeSceneProps> = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [])
    const slides = [
        <div>
            <Header menuItems={menuItems} />

            <div style={styles.section1.container}>
                <div>
                    <div style={{ padding: isMobile ? 10 : 70 }}>
                        <SimpleCard
                            title={sections[0].title}
                            description={sections[0].description}
                            btnText={sections[0].btnText}
                            onClick={() => { navigate('/produits') }}
                        />
                    </div>
                </div>
                <div>
                    <video
                        playsInline
                        ref={videoRef}
                        autoPlay
                        loop
                        muted
                        style={{
                            width: '75%', // Set the desired width
                            height: 'auto', // Adjust the height accordingly
                            objectFit: 'cover', // Maintain aspect ratio and cover parent container
                        }}>
                        <source src={myVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
        ,

        <div style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
        }}>
            <img src={expo} alt="studio_code" style={{
                width: '100%',
                height: '100vh',
            }} />
            <div style={{
                bottom: 0,
                width: 600,
                textAlign: 'center',
                ...styles.section2.cardContainer
            }}>
                {!isMobile && <SimpleCard
                    title={sections[1].title}
                    description={sections[1].description}
                    btnText={sections[1].btnText}
                    onClick={() => { navigate('/commander') }}
                    revert
                />}  </div>
        </div>
        ,
        <div style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
        }}>
            <img src={aeroport} alt="aeroport_code" style={{
                width: '100%',
                height: '100vh',
            }} />
            <div style={{
                top: 0,
                ...styles.section2.cardContainer,
                width: 600,
                textAlign: 'center',
            }}>
                {!isMobile && <SimpleCard
                    title={sections[2].title}
                    description={sections[2].description}
                    btnText={sections[2].btnText}
                    onClick={() => { navigate('/studio') }}
                    revert
                />}
            </div>
        </div>
        , <div style={{
            position: 'relative',
        
        }}>
            <div style={{
                ...styles.section4.container, flexDirection: isMobile ? 'column' : 'row', ...{
                    height: window.innerHeight - 65,
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }
            }}>
                <div style={styles.section4.row}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ ...styles.section4.text, width: '100%', ...{ fontSize: isMobile ? 16 : 30 } }}>
                        {'Pourquoi nos supports ?'}
                    </Typography>
                </div>
                <div style={styles.section4.row}>
                    <VerticalSlider />
                </div>

            </div >
            <SimpleFooter />
        </div>
    ];

    if (!isMobile) return <div className='container'>
        {
            slides.map((slide, index) => {
                return (<div className='section' key={index}>
                    {slide}
                </div>)
            })
        }
    </div>

    let cbgcolor = '#0a1d56';
    if (/iPhone/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent)) {
        cbgcolor = '#0D1B4C'
    }
    return (<HomeLayout >
        {/* Section 1 */}
        <Grid style={{ backgroundColor: cbgcolor }} container spacing={2} alignItems={'center'}>
            <Grid item xs={12} md={6}>
                <div style={{ padding: isMobile ? 10 : 70 }}>
                    <SimpleCard
                        title={sections[0].title}
                        description={sections[0].description}
                        btnText={sections[0].btnText}
                        onClick={() => { navigate('/produits') }}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6} >
                <video
                    playsInline
                    ref={videoRef}
                    muted
                    autoPlay
                    loop
                    style={{
                        width: '100%', // Set the desired width
                        height: 'auto', // Adjust the height accordingly
                        objectFit: 'cover', // Maintain aspect ratio and cover parent container
                    }}>
                    <source src={myVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Grid>
        </Grid>
        {/* Section 2 */}
        <div style={styles.section2.container}>
            <img src={expo} alt="studio_code" style={styles.section2.img} />
            <div style={{ ...styles.section2.cardContainer, ...{ fontSize: isMobile ? 13 : 30, width: isMobile ? '100%' : '50%' } }}>
                {isMobile && <Box sx={{ display: 'flex', justifyContent: `center`, marginBottom: 2, color: 'white' }}>
                    <Button variant="outlined" onClick={() => { navigate('/commander') }} color={`inherit`} endIcon={<ArrowForwardIcon />}>
                        {sections[1].btnText}
                    </Button>
                </Box>}
                {!isMobile && <SimpleCard
                    title={sections[1].title}
                    description={sections[1].description}
                    btnText={sections[1].btnText}
                    onClick={() => { navigate('/commander') }}

                />}
            </div>
        </div>
        {isMobile && <div style={{ padding: 15 }}>
            <Typography variant="h5" component="div" sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: isMobile ? 22 : 40,
                textAlign: 'center',
                color: '#0D1B4C'
            }}>
                {sections[1].title}
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 100,
                fontSize: isMobile ? 16 : 24,
                textAlign: isMobile ? 'center' : 'start',
                color: '#0D1B4C'

            }}>
                {sections[1].description}
            </Typography>
        </div>}
        {/* Section 3 */}
        <div style={styles.section3.container}>
            <img src={aeroport} alt="studio_code" style={styles.section3.img} />

            <div style={{ ...styles.section2.cardContainer, ...{ fontSize: isMobile ? 13 : 30, width: isMobile ? '100%' : '50%' } }}>
                {isMobile && <Box sx={{ display: 'flex', justifyContent: `center`, marginTop: 2, color: 'white' }}>
                    <Button variant="outlined" onClick={() => { navigate('/studio') }} color={`inherit`} endIcon={<ArrowForwardIcon />}>
                        {sections[2].btnText}
                    </Button>
                </Box>}
                {!isMobile && <SimpleCard
                    title={sections[2].title}
                    description={sections[2].description}
                    btnText={sections[2].btnText}
                    onClick={() => { navigate('/studio') }}
                    revert
                />}
            </div>
        </div>
        {
            isMobile &&
            <div style={{ padding: 15 }}>
                <Typography variant="h5" component="div" sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: isMobile ? 22 : 40,
                    textAlign: 'center',
                    color: '#0D1B4C'
                }}>
                    {sections[2].title}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: isMobile ? 16 : 24,
                    textAlign: isMobile ? 'center' : 'start',
                    color: '#0D1B4C'

                }}>
                    {sections[2].description}
                </Typography>
            </div>
        }
        {/* Section 4 */}
        <div style={{ ...styles.section4.container, flexDirection: isMobile ? 'column' : 'row' }}>
            <div style={styles.section4.row}>
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ ...styles.section4.text, ...{ fontSize: isMobile ? 16 : 30 } }}>
                    {'Pourquoi nos supports ?'}
                </Typography>
            </div>
            <div style={styles.section4.row}>
                <VerticalSlider />
            </div>
        </div>
    </HomeLayout >
    );
}

export default HomeScene;

const styles: CssType = {
    section1: {
        container: {
            height: '100vh',
            width: '100vw',
            justifyContent: 'space-around',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#0a1d56'
        }
    },
    section2: {
        container: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-around',
            // marginTop: 450
        },
        img: { width: '100%', height: '100%' },
        cardContainer: {
            position: 'absolute',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            color: 'black',

        }
    },
    section3: {
        container: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
            // zIndex: 10,
        },
        img: { width: '100%', height: '100%' },
    },
    section4: {
        container: { display: 'flex', alignItems: 'center' },
        text: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            padding: 5
        }
    }
}