import React, { FC } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import { cgu } from '../../../datalayer/legal/cgu';

interface CGUProps {

}

const CGU: FC<CGUProps> = () => {
    return (<HomeLayout >
        <div dangerouslySetInnerHTML={{ __html: cgu }} />
    </HomeLayout>)  ;
}

export default CGU;