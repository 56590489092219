import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  addClient,
  deleteClient,
  editClientProduct,
  fetchClientsService,
  fetchProductsLine,
} from "./services";
import { adaptProducts } from "./adapters";
import { AddClientInput, EditClientProductInput } from "./types";

export const fetchClientsAction = createAsyncThunk(
  "clients/list",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      // Your API call logic here using Axios
      const { data } = (await fetchClientsService(
        state.auth.token ?? ""
      )) as any;
      const { data: productLines } = (await fetchProductsLine(
        state.auth.token ?? ""
      )) as any;
      return {
        clients: data,
        productLines: adaptProducts(productLines.data),
      };
    } catch {}
  }
);

export const editClientProductAction = createAsyncThunk(
  "clients/edit-product",
  async (input: EditClientProductInput, { getState, dispatch }) => {
    try {
      const { id, productId } = input;
      const state = getState() as RootState;
      // Your API call logic here using Axios
      (await editClientProduct(state.auth.token ?? "", id, productId)) as any;
      dispatch(fetchClientsAction());
    } catch {}
  }
);
export const addClientAction = createAsyncThunk(
  "clients/add-client",
  async (input: AddClientInput, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      // Your API call logic here using Axios
      (await addClient(state.auth.token ?? "", {
        ...input,
        password: "DEFAULTPASSWORD",
        username: input.email.split("@")[0] ?? "",
        role: 3,
      })) as any;
      dispatch(fetchClientsAction());
    } catch {}
  }
);

export const deleteClientAction = createAsyncThunk(
  "clients/delete-client",
  async (id: number, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      // Your API call logic here using Axios
      (await deleteClient(state.auth.token ?? "", id)) as any;
      dispatch(fetchClientsAction());
    } catch {}
  }
);
