// VerticalSlider.tsx
import React, { useState } from 'react';
import './VerticalSlider.css';
import { slider } from 'datalayer/home';
import { useMediaQuery, useTheme } from '@mui/material';

// ... (rest of the component setup)

const VerticalSlider: React.FC = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const middleSlide = Math.floor(slider.length / 2);
    const [activeSlide, setActiveSlide] = useState(middleSlide);

    const slideHeight = 100; // Adjust this based on your slide height
    const sliderHeight = slider.length * slideHeight;

    const handleSlideChange = (newSlide: number) => {
        setActiveSlide(newSlide);
    };

    // Calculate the offset to center the active slide
    const sliderOffset = -activeSlide * slideHeight + (sliderHeight - slideHeight) / 2;

    // Calculate the index of the next slide cyclically
    const nextSlideIndex = (activeSlide + 1) % slider.length;

    return (
        <div style={{ padding: 10 }}>
            <div className="vertical-slider">
                <div
                    className="slider-content"
                    style={{ transform: `translateY(${sliderOffset}px)` }}
                >
                    {slider.map((slide, index) => {
                        // const cyclicIndex = (index - activeSlide + slider.length) % slider.length;
                        return (
                            <div
                                onClick={() => { setActiveSlide(index) }}
                                key={index}
                                className={`myslide ${index === activeSlide ? 'active' : ''}`}
                                style={{
                                    color: index === activeSlide ? '#0E1B4D' : 'black',
                                    fontWeight: index === activeSlide ? 'Bold' : 'normal',
                                    fontSize: isMobile ? 20 : (index === activeSlide ? 26 : 22),
                                    textAlign: 'center',
                                    cursor: 'pointer'

                                }}
                            >
                                {slide}
                            </div>
                        );
                    })}
                </div>
                {/* Dots */}
                <div className="dots" style={{ marginRight: isMobile ? 0 : 50 }}>
                    {slider.map((_, index) => (
                        <div
                            key={index}
                            className={`dot ${index === activeSlide ? 'active' : ''}`}
                            onClick={() => handleSlideChange(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VerticalSlider;
