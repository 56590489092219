export const legalMentions = `<div>
<style>
    h1 {
        text-align: center;
        font-size: 20px;
        font-family: Helvetica, Arial, sans-serif;
        margin: 0;

    }

    p {
        margin-bottom: 10px;
        font-size: 15 px;
        font-family: Helvetica, Arial, sans-serif;
    }

    .content {
        max-width: 800px;
        margin: 0 auto;
        padding: 50px;
        height: 100vh;
    }

    .footer {
        text-align: center;
        margin-top: 50px;
    }
</style>
<div class="content">
    <h1>Mentions Légales</h1>
    <p>IDENTIFICATION DE L'ÉDITEUR </p>
    <p>Le site Starget Holographics (ci-après « le Site »), accessible à l’adresse www.stargetholographics.com est
        édité par la société STARGET HOLOGRAPHICS (ci-après « l'Éditeur »), SAS au capital de 5,000.00 euros, dont
        le siège social est situé 60 Rue François 1ER, 75008 Paris, France.
        La société est immatriculée au RCS de Paris sous le numéro SIREN 922595053. TVA intracommunautaire n°
        FR72922595053
    </p>
    <p>L'Éditeur peut être contacté à l’adresse mail contact@stargetholographics.com. </p>
    <h1>HÉBERGEUR DU SITE INTERNET </h1>
  
    <p>Ce site est hébergé sur des datacenters en Europe (Irlande et Allemagne) de la société Amazon Web Services (AWS):</p>

 <p>Amazon Web Services Inc.<br/>
 410 Terry Avenue North,<br/>
 Seattle, WA 98109-5210, USA<br/>
http://aws.amazon.com</p>
</div>

</div>`;