import React, { useLayoutEffect } from "react";
import Home from "../features/public/home/HomeView";
import Products from "../features/public/products/ProductsView";
import Applications from "../features/public/applications/ApplicationsView";
import Order from "../features/public/order/OrderView";
import {
  Route,
  Routes as RoutesContainer,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PrivacyPolicy from "../features/public/legal/PrivacyPolicy";
import CGU from "../features/public/legal/CGU";
import LegalMentions from "../features/public/legal/LegalMentions";
import Studio from "../features/public/studio/StudioView";
import ClientInfoView from "../features/client/client-info/ClientInfoView";
import SupportView from "features/client/support/SupportView";
import { ClientView } from "features/admin/client/ClientView";
import ManagementView from "features/management/ManagementView";
import LoginView from "features/authentication/LoginView";
import { useSelector } from "react-redux";
import { getUserRole } from "store/auth/selectors";
import ResetPwdView from "features/authentication/ResetPwdView";
import PrivateComponent from "./PrivateRoute";
import { LogoutView } from "features/authentication/LogoutView";
import { MediaBankView } from "features/media-bank/MediaBankView";

function Routes() {
  const location = useLocation();
  const role = useSelector(getUserRole);
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <RoutesContainer>
      <Route path="/" Component={Home} />
      {/* <Route path="/scroll" Component={TeslaSlider} /> */}
      {/* PUBLIC ROUTES */}
      <Route path="/produits/:target?" Component={Products} />
      <Route path="/studio" Component={Studio} />
      <Route path="/realisations" Component={Applications} />
      <Route path="/commander" Component={Order} />
      <Route path="/politque-de-confidentialite" Component={PrivacyPolicy} />
      <Route path="/cgv" Component={CGU} />
      <Route path="/mentions-legales" Component={LegalMentions} />
      <Route path="/login" Component={LoginView} />
      <Route path="/logout" Component={LogoutView} />
      <Route path="/reset-pwd" Component={ResetPwdView} />
      {/* CLIENT ROUTES */}
      {/* {renderClientRoutes()} */}

      {/* <Route path="/client" Component={Admin} /> */}

      {/* ADMIN ROUTES */}
      <Route
        path="/clients"
        element={
          <PrivateComponent
            roles={["ADMIN"]}
            userRole={role}
            component={ClientView}
          />
        }
      />
      <Route
        path="/banque-image"
        element={
          <PrivateComponent
            roles={["ADMIN", "CLIENT"]}
            userRole={role}
            component={MediaBankView}
          />
        }
      />

      {/* {renderAdminRoutes()} */}
      <Route
        path="/gestion"
        element={
          <PrivateComponent
            roles={["CLIENT"]}
            userRole={role}
            component={ManagementView}
          />
        }
      />
      <Route
        path="/support"
        element={
          <PrivateComponent
            roles={["CLIENT"]}
            userRole={role}
            component={SupportView}
          />
        }
      />
      <Route
        path="/info-personnelles"
        element={
          <PrivateComponent
            roles={["CLIENT"]}
            userRole={role}
            component={ClientInfoView}
          />
        }
      />
      {/* <Route component={NotFound} /> */}
    </RoutesContainer>
  );
}

export default Routes;
