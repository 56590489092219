import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { addClientAction } from "store/admin/thunkActions";

interface ClientFormValues {
  company: string;
  phone: string;
  name: string;
  last_name: string;
  email: string;
}

const validationSchema = Yup.object().shape({
  company: Yup.string().required("Ce champ est requis"),
  phone: Yup.string().required("Ce champ est requis"),
  name: Yup.string().required("Ce champ est requis"),
  last_name: Yup.string().required("Ce champ est requis"),
  email: Yup.string().email("Invalide E-mail").required("Ce champ est requis"),
});

const initialValues: ClientFormValues = {
  company: "",
  phone: "",
  name: "",
  last_name: "",
  email: "",
};

const useStyles: any = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 10,
    width: 400,
  },
}));

interface RegisterClientModalProps {
  open: boolean;
  onClose: () => void;
}

const RegisterClientModal: React.FC<RegisterClientModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values: ClientFormValues, { setSubmitting }: any) => {
    dispatch(addClientAction(values as any) as any);
    setSubmitting(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.modalContent}>
        <h2>Ajouter un client</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                as={TextField}
                name="last_name"
                label="Nom"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="last_name" />}
              />
              <Field
                as={TextField}
                name="name"
                label="Prénom"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                name="email"
                label="E-mail"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="email" />}
              />
              <Field
                as={TextField}
                name="company"
                label="Entreprise"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="company" />}
              />
              <Field
                as={TextField}
                name="phone"
                label="Téléphone"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="phone" />}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Enregistrer
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Annuler
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RegisterClientModal;
