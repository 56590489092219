import * as React from 'react';
import OrderScene from './OrderScene';
interface OrderViewProps {
    
}
 
const OrderView: React.FC<OrderViewProps> = () => {
    return (  <OrderScene />);
}
 
export default OrderView;