import React, { FC, ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
type Links = {
    title: string;
    icon: any;
    to: string;
}

type Props = {
    links: Links[]
}

export const LinksCard: FC<Props> = ({
    links
}) => {
    const navigate = useNavigate()

    return (<>
        {links.map(({ title, icon, to }) => {
            return <div style={{
                display: 'flex', padding: 8,
                cursor: 'pointer',
            }}>
                {icon}
                <div style={{
                    marginLeft: 5,
                    color: '#000',
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                }} onClick={() => { navigate(to) }} >{title}</div>
            </div >
        })}
    </>)
}