export const sections = [
    {
        title: 'Rencontrez Helios & Solaris',
        description: `Starget Holographics propose des supports d’affichages à technologie holographique.
            Devenez un acteur de l’innovation en communiquant sur ce nouveau support !`,
        btnText: 'Nos produits'
    },
    {
        title: 'Le futur de la communication',
        description: `Des supports adaptés pour tous types d’usages, pour mettre en lumière votre activité et attirer près de 30% de visiteurs supplémentaires.`,
        btnText: 'Commander'
    },
    {
        title: 'Une offre clé en main',
        description: `Le Studio vous accompagne dans la création de contenu à diffuser sur vos supports holographiques.
        Des services sur-mesure à votre disposition tout au long de notre collaboration.`,
        btnText: 'Studio'
    }
]

export const slider = [
    'Deux dimensions : Helios (1,78x1,35m) et Solaris (1,63x0,70m)',
    'Luminosité adaptée à toute exposition (entre 1900 et 5500 cd/m² en fonction des supports)',
    'Des produits développés et assemblés en France',
    'Augmentez le nombre de visiteurs de près de 30%',
    'Doublez le temps accordé à vos messages',
    'Consommation électrique 1,64x moins importante qu’un écran Led classique',
    'Supports protégés et mobiles',
    'Expérience utilisateur optimisée et gestion simplifiée',
]