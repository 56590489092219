export const privacyPolicy = `<div>
<style>
    h1 {
        text-align: center;
        font-size: 20px;
        font-family: Helvetica, Arial, sans-serif;
        margin: 0;

    }

    p {
        margin-bottom: 10px;
        font-size: 15 px;
        font-family: Helvetica, Arial, sans-serif;
    }

    .content {
        max-width: 800px;
        margin: 0 auto;
        padding: 50px;
        Height: 100%;
    }

    .footer {
        text-align: center;
        margin-top: 50px;
    }
</style>
<div class="content">
    <h1>POLITIQUE DE CONFIDENTIALITE – STARGET HOLOGRAPHICS</h1>
    <p>Cette politique de confidentialité décrit comment Starget Holographics collecte, utilise et protège les
        données personnelles des utilisateurs lors de leur utilisation de notre site web. En utilisant notre site,
        vous consentez à la collecte et à l'utilisation de vos données conformément à cette politique de
        confidentialité.</p>
    <h1>Définitions</h1>
    <p>L'Éditeur : Starget Holographics, la société par actions simplifiée, immatriculée sous le SIREN 922595053,
        qui édite les services de communication au public en ligne.
        Le Site : L'ensemble des sites, pages Internet et services en ligne proposés par Starget Holographics.
        L'Utilisateur : La personne utilisant le Site et les services.
    </p>
    <h1>Nature des données collectées</h1>
    <p>Dans le cadre de l'utilisation des Sites, Starget Holographics est susceptible de collecter les catégories de
        données suivantes concernant ses Utilisateurs :</p>

    <p>
        <li>Données d'état civil, d'identité, d'identification, etc.</li>
        <li>Données relatives à la vie professionnelle, telles que l’entreprise, le poste, les distinctions, etc.
        </li>
        <li>Données de connexion, telles que les adresses IP, les journaux d'événements, etc.</li>
        <li>Données de localisation, telles que les déplacements, les données GPS, les données GSM, etc.</li>
    </p>
    <h1>Communication des données personnelles à des tiers</h1>
    <p>Vos données ne font l'objet d'aucune communication à des tiers, sauf dans les cas suivants :
        <li>Si cela est nécessaire pour se conformer à une loi, un règlement ou une décision d'une autorité
            réglementaire ou judiciaire compétente.</li>
        <li>Dans le cas d'une fusion, d'une acquisition ou de toute autre forme de cession d'actifs, où nous
            garantirons la confidentialité de vos données personnelles et vous informerons avant que celles-ci ne
            soient transférées ou soumises à de nouvelles règles de confidentialité.</li>

    </p>
    <h1>Finalité de la réutilisation des données personnelles collectées</h1>
    <p>Nous collectons et utilisons vos données personnelles dans le but de réaliser les opérations suivantes :
        <li>Programme de fidélité.</li>
        <li>Suivi de la relation client, y compris les enquêtes de satisfaction, la gestion des réclamations et du
            service après-vente.</li>
        <li>Sélection de clients pour des études, sondages et tests produits, à condition de recueillir leur
            consentement et de ne pas établir de profils sensibles.</li>
        <li>Opérations de prospection, y compris les actions de fidélisation, les sondages, les tests produits et
            les promotions.</li>
        <li>Élaboration de statistiques commerciales.</li>
        <li>Gestion des avis des personnes sur nos produits, services ou contenus.</li>



    </p>
    <h1>Agrégation des données</h1>
    <p>Nous pouvons publier, divulguer et utiliser des informations agrégées et des informations non personnelles à
        des fins d'analyse du secteur et du marché, de profilage démographique, à des fins promotionnelles,
        publicitaires et commerciales, ainsi que pour d'autres fins commerciales.
        Nous veillons à ce que ces informations ne permettent pas l'identification ou la mention d'un Utilisateur
        individuel.
    </p>
    <h1>Collecte des données d'identité et d'identification</h1>
    <p>Lorsque vous utilisez notre Site et interagissez avec nos services, nous pouvons collecter des informations
        d'identité et d'identification vous concernant.
        Les types de données que nous pouvons collecter comprennent, sans s'y limiter, votre nom, votre adresse
        e-mail, votre adresse postale, votre numéro de téléphone, votre date de naissance, votre genre, votre
        profession, et toute autre information que vous choisissez de nous fournir.
    </p>
    <p>Nous collectons ces données d'identité et d'identification dans le but de vous identifier en tant
        qu'Utilisateur de nos services, de vous fournir un accès personnalisé à certaines fonctionnalités et de vous
        contacter en cas de besoin.
        Nous pouvons également utiliser ces informations pour vérifier votre identité lors de la création et de la
        gestion de votre compte.
    </p>
    <p>Il est important de noter que nous ne collectons pas intentionnellement de données sensibles, telles que des
        informations relatives à votre origine ethnique, vos croyances religieuses, votre orientation sexuelle ou
        votre état de santé. Nous vous encourageons à ne pas nous fournir de telles informations via nos services.
    </p>
    <p> Nous pouvons collecter vos données d'identité et d'identification de différentes manières, notamment lorsque
        vous les saisissez directement sur notre Site, lorsque vous vous inscrivez à notre service, lorsque vous
        nous contactez par le biais de nos canaux de communication, ou lorsque nous les obtenons légalement auprès
        de sources tierces.
    </p>
    <p>Nous nous engageons à protéger la confidentialité de vos données d'identité et d'identification. Nous mettons
        en place des mesures de sécurité appropriées pour prévenir tout accès non autorisé, toute divulgation, toute
        modification ou toute destruction de vos données.
        Si vous avez des questions ou des préoccupations concernant la collecte de vos données d'identité et
        d'identification, veuillez nous contacter en utilisant les coordonnées fournies dans la section "Nous
        contacter" de notre Site.
    </p>

    </p>
    <h1>Collecte des données du terminal</h1>
    <p>Certaines des données techniques de votre appareil sont collectées automatiquement par le Site. Ces
        informations incluent notamment votre adresse IP, fournisseur d’accès à Internet, configuration matérielle,
        configuration logicielle, type et langue du navigateur… La collecte de ces données est nécessaire à la
        fourniture des services.
    </p>
    <p>Les données techniques de votre appareil sont automatiquement collectées et enregistrées par le Site, à des
        fins publicitaires, commerciales et statistiques. Ces informations nous aident à personnaliser et à
        améliorer continuellement votre expérience sur notre Site. Nous ne collectons ni ne conservons aucune donnée
        nominative (nom, prénom, adresse…) éventuellement attachée à une donnée technique. Les données collectées
        sont susceptibles d’être revendues à des tiers.
    </p>
    <h1>Cookies</h1>
    <p>Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13 mois au
        maximum après leur premier dépôt dans le terminal de l’Utilisateur, tout comme la durée de la validité du
        consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies n’est pas
        prolongée à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l’issue de ce délai.
    </p>
    <h1>Finalité cookies</h1>
    <p>Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus à
        l’Utilisateur, à partir du traitement des informations concernant la fréquence d’accès, la personnalisation
        des pages ainsi que les opérations réalisées et les informations consultées.
        Vous êtes informé que l’Éditeur est susceptible de déposer des cookies sur votre terminal. Le cookie
        enregistre des informations relatives à la navigation sur le service (les pages que vous avez consultées, la
        date et l’heure de la consultation…) que nous pourrons lire lors de vos visites ultérieures.
    </p>
    <p>Vous reconnaissez avoir été informé que l’Éditeur peut avoir recours à des cookies, et l’y autorisez. Si vous
        ne souhaitez pas que des cookies soient utilisés sur votre terminal, la plupart des navigateurs vous
        permettent de désactiver les cookies en passant par les options de réglage. Toutefois, vous êtes informé que
        certains services sont susceptibles de ne plus fonctionner correctement.
    </p>
    <p>L’Éditeur peut être amenée à recueillir des informations de navigation via l’utilisation de cookies.</p>
    <h1>Conservation des données techniques
    </h1>
    <p>Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités
        visées ci-avant.</p>
    <p>Conservation des données pendant la durée de la relation contractuelle
        Conformément à l’article 6-5° de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
        aux libertés, les données à caractère personnel faisant l’objet d’un traitement ne sont pas conservées
        au-delà du temps nécessaire à l’exécution des obligations définies lors de la conclusion du contrat ou de la
        durée prédéfinie de la relation contractuelle.
    </p>
    <p>Nous conservons les données personnelles pour la durée strictement nécessaire à la réalisation des finalités
        décrites dans les présentes CGU. Au-delà de cette durée, elles seront anonymisées et conservées à des fins
        exclusivement statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
    </p>
    <h1>Suppression des données après suppression du compte</h1>
    <p>Des moyens de purge de données sont mis en place afin d’en prévoir la suppression effective dès lors que la
        durée de conservation ou d’archivage nécessaire à l’accomplissement des finalités déterminées ou imposées
        est atteinte. Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
        libertés, vous disposez par ailleurs d’un droit de suppression sur vos données que vous pouvez exercer à
        tout moment en prenant contact avec l’Éditeur.</p>
    <p>Pour des raisons de sécurité, si vous ne vous êtes pas authentifié sur le Site pendant une période de trois
        ans, vous recevrez un e-mail vous invitant à vous connecter dans les plus brefs délais, sans quoi vos
        données seront supprimées de nos bases de données.
    </p>
    <h1>Suppression du compte
    </h1>
    <p>L’Utilisateur a la possibilité de supprimer son Compte à tout moment, par simple demande à l’Éditeur OU par
        le menu de suppression de Compte présent dans les paramètres du Compte le cas échéant.</p>
    <h1>Suppression du compte en cas de violation des CGU
    </h1>
    <p>En cas de violation d’une ou de plusieurs dispositions des CGU ou de tout autre document incorporé aux
        présentes par référence, l’Éditeur se réserve le droit de mettre fin ou restreindre sans aucun avertissement
        préalable et à sa seule discrétion, votre usage et accès aux services, à votre compte et à tous les Sites.
    </p>
    <h1>Indications en cas de faille de sécurité décelée par l'Éditeur</h1>
    <p>Nous nous engageons à mettre en œuvre toutes les mesures techniques et organisationnelles appropriées afin de
        garantir un niveau de sécurité adapté au regard des risques d’accès accidentels, non autorisés ou illégaux,
        de divulgation, d’altération, de perte ou encore de destruction des données personnelles vous concernant.
    </p>
    <p>Dans l’éventualité où nous prendrions connaissance d’un accès illégal aux données personnelles vous
        concernant stockées sur nos serveurs ou ceux de nos prestataires, ou d’un accès non autorisé ayant pour
        conséquence la réalisation des risques identifiés ci-dessus, nous nous engageons à :</p>
    <p>- Vous notifier l’incident dans les plus brefs délais ;<br>
        - Examiner les causes de l’incident et vous en informer ;<br>
        - Prendre les mesures nécessaires dans la limite du raisonnable afin d’amoindrir les effets négatifs et
        préjudices pouvant résulter dudit incident.
    </p>
    <p>Limitation de la responsabilité :
        En aucun cas les engagements définis au point ci-dessus relatifs à la notification en cas de faille de
        sécurité ne peuvent être assimilés à une quelconque reconnaissance de faute ou de responsabilité quant à la
        survenance de l’incident en question.
    </p>
    <h1>Transfert des données personnelles à l'étranger
    </h1>
    <p>L’Éditeur s’engage à ne pas transférer les données personnelles de ses Utilisateurs en dehors de l’Union
        européenne.
    </p>
    <h1>Modification des CGU et de la politique de confidentialité
    </h1>
    <p>En cas de modification des présentes CGU, engagement de ne pas baisser le niveau de confidentialité de
        manière substantielle sans l’information préalable des personnes concernées
    </p>
    <p>Nous nous engageons à vous informer en cas de modification substantielle des présentes CGU, et à ne pas
        baisser le niveau de confidentialité de vos données de manière substantielle sans vous en informer et
        obtenir votre consentement.</p>

    <h1>Droit applicable et modalités de recours
    </h1>
    <p>Application du droit français (législation CNIL) et compétence des tribunaux :
        Les présentes CGU et votre utilisation du Site sont régies et interprétées conformément aux lois de France,
        et notamment à la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés.
        Le choix de la loi applicable ne porte pas atteinte à vos droits en tant que consommateur conformément à la
        loi applicable de votre lieu de résidence.
        Si vous êtes un consommateur, vous et nous acceptons de se soumettre à la compétence non-exclusive des
        juridictions françaises, ce qui signifie que vous pouvez engager une action relative aux présentes CGU en
        France ou dans le pays de l’UE dans lequel vous vivez.
        Si vous êtes un professionnel, toutes les actions à notre encontre doivent être engagées devant une
        juridiction en France.
    </p>

    <p>En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas d’échec
        de ces tentatives, toutes contestations à la validité, l’interprétation et / ou l’exécution des présentes
        CGU devront être portées même en cas de pluralité des défendeurs ou d’appel en garantie, devant les
        tribunaux français.
    </p>
    <h1>Portabilité des données
    </h1>
    <p>L’Éditeur s’engage à vous offrir la possibilité de vous faire restituer l’ensemble des données vous
        concernant sur simple demande. L’Utilisateur se voit ainsi garantir une meilleure maîtrise de ses données,
        et garde la possibilité de les réutiliser. Ces données devront être fournies dans un format ouvert et
        aisément réutilisable.
    </p>

</div>
</div>`;