import React, { FC, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, IconButton, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type Props = {
  label: string;
  subLabel?: string;
  onChange: (name: string, count: number) => void;
  name: string;
  min: number;
  max?: number;
  value?: number;
};
// Define the component
const CounterInput: FC<Props> = ({
  label,
  subLabel,
  onChange,
  name,
  min,
  max,
  value = min,
}) => {
  const classes = useStyles();
  const [count, setCount] = useState(value);

  const handleIncrement = () => {
    if (max && count === max) return;
    setCount(count + 1);
    onChange(name, count + 1);
  };

  const handleDecrement = () => {
    if (min !== undefined && count > min) {
      setCount(count - 1);
      onChange(name, count - 1);
    }
  };
  return (
    <Box className={classes.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "space-around",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontFamily: "Poppins", textDecorationLine: "underline" }}
          className={classes.label}
        >
          {label}
        </Typography>
        <Typography
          variant="caption"
          style={{ fontFamily: "Poppins" }}
          className={classes.subLabel}
        >
          {subLabel}
        </Typography>
      </div>
      <div className={classes.counterContainer}>
        <IconButton
          color="primary"
          size="small"
          onClick={handleDecrement}
          className={classes.button}
        >
          <RemoveIcon />
        </IconButton>
        <Typography
          variant="body1"
          style={{ fontFamily: "Poppins", fontSize: 18 }}
          className={classes.count}
        >
          {count}
          <input type="hidden" value={count} />
        </Typography>
        <IconButton
          color="primary"
          size="small"
          onClick={handleIncrement}
          className={classes.button}
        >
          <AddIcon />
        </IconButton>
      </div>
    </Box>
  );
};

// Custom styles
const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    margin: 2,
  },
  subLabel: {
    margin: 2,
    color: "#000",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontStyle: "italic",
  },
  counterContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    border: `1px solid rgba(14, 27, 77, 0.70)`,
    marginLeft: 10,
  },
  button: {
    padding: 2,
  },
  count: {
    margin: `0 2px`,
    color: "#000",
    fontSize: " 24px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
}));

export default CounterInput;
