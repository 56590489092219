import * as React from "react";
import ManagementScene from "./ManagementScene";

interface ManagementViewProps {}

const ManagementView: React.FC<ManagementViewProps> = () => {
  return (
    <>
      <ManagementScene />
    </>
  );
};

export default ManagementView;
