import { Container, Grid } from "@mui/material";
import HomeLayout from "components/layouts/HomeLayout";
import React, { FC, useEffect, useState } from "react";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientsAction } from "store/admin/thunkActions";
import { getClients, getProductLines } from "store/admin/selectors";
import { StickyHeadTable } from "./Table";
import RegisterClientModal from "./ClientForm";

export const ClientScene: FC = ({}) => {
  const dispatch = useDispatch();
  const clients = useSelector(getClients);
  const products = useSelector(getProductLines);
  const [showAddModal, setShowAddModal] = useState(false);
  
  useEffect(() => {
    dispatch(fetchClientsAction() as any);
  }, []);

  return (
    <HomeLayout>
      <Container style={{ minHeight: "78vh" }}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <h2>Comptes clients</h2>
          </Grid>
          <Grid item alignSelf={"center"}>
            <AddCircleOutline
              fontSize={"large"}
              onClick={() => {
                setShowAddModal(true);
              }}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 15 }}>
          <StickyHeadTable data={clients} products={products} />
        </div>
      </Container>
      <RegisterClientModal
        onClose={() => {
          setShowAddModal(false);
        }}
        open={showAddModal}
      />
    </HomeLayout>
  );
};
